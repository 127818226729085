import React, { useRef } from "react";
import { ModalBase, ModalBaseProps } from "componentsV2/Modal";

export type FormModalProps = Pick<ModalBaseProps, "active" | "handleClose"> & {
  children: React.ReactNode;
  title: string;
  handleSubmit: (data: FormData) => void;
  actions: React.ReactNode;
};

export const FormModal = ({
  active,
  handleClose,
  children,
  title,
  handleSubmit,
  actions,
}: FormModalProps) => {
  const initialFocusRef = useRef<HTMLButtonElement>(
    null
  ) as React.MutableRefObject<HTMLButtonElement>;

  const onFormSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    handleSubmit(formData);
  };

  return (
    <ModalBase
      initialFocusRef={initialFocusRef}
      active={active}
      handleClose={handleClose}
      title={title}
      showCloseButton
    >
      <form onSubmit={onFormSubmit} className="space-y-6 sm:min-w-[512px]">
        <div className="text-lg text-gray-900 mb-6 font-medium">{title}</div>
        {children}
        <div className="flex justify-end gap-3">{actions}</div>
      </form>
    </ModalBase>
  );
};
