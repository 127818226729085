import React, { useEffect, useId, useState } from "react";
import cn from "classnames";

import { useLayout } from "contexts/LayoutContext";
import { Path } from "routes/types";

import { ExpandButton } from "./components/ExpandButton";
import { NavLink } from "./components/NavLink";
import { ProfileLink } from "./components/ProfileLink";
import { navigation as initialNavigation, supportNavLink } from "./constants";
import { useDashboard } from "contexts/DashboardContext";
import { useSelector } from "hooks/redux";
import { agentSelector } from "state/agentSlice";
import { FeatureName } from "api/types";
import { isOrganizationFeatureEnabled } from "utils/organization";

interface SidebarProps {
  notifications: Partial<Record<Path, string>>;
}

export const SidebarLinks: React.FC<SidebarProps> = ({ notifications }) => {
  const [navigation, setNavigation] = useState(initialNavigation);
  const { isAsyncTasksEnabled } = useDashboard();

  const agent = useSelector(agentSelector);

  const isZendeskEnabled =
    !!agent.organization &&
    isOrganizationFeatureEnabled(agent.organization, [FeatureName.Zendesk]);

  useEffect(() => {
    const nextNavigation = [...initialNavigation];

    if (!isAsyncTasksEnabled) {
      // removes tasks tab link
      delete nextNavigation[1];
    }

    setNavigation(nextNavigation);
  }, [isAsyncTasksEnabled]);

  return (
    <ul>
      {navigation.map(({ path, label, Icon }) => (
        <li key={path}>
          <NavLink
            path={path}
            label={label}
            notifications={notifications[path]}
            Icon={Icon}
          />
        </li>
      ))}
      {isZendeskEnabled && supportNavLink.path && (
        <li>
          <NavLink
            path={supportNavLink.path}
            label={supportNavLink.label}
            Icon={supportNavLink.Icon}
            newTab
          />
        </li>
      )}
    </ul>
  );
};

export const Sidebar: React.FC<SidebarProps> = ({ notifications }) => {
  const sidebarId = useId();
  const { isAway, sidebarExpanded } = useLayout();

  return (
    <div
      id={sidebarId}
      className={cn("fixed inset-y-0 flex flex-col", {
        "w-64": sidebarExpanded,
        "top-12": isAway,
      })}
    >
      <div className="flex flex-col min-h-0 flex-1 bg-gray-800">
        <h1 className="flex items-center flex-shrink-0 py-5 px-4">
          <img className="w-8" src="/logo-light.svg" alt="" />
          <span
            className={cn("ml-3 text-2xl text-white", {
              "sr-only": !sidebarExpanded,
            })}
          >
            VMOS
          </span>
        </h1>

        <nav className="flex-1 px-2 overflow-y-auto">
          <ul className="flex flex-col space-y-1">
            <SidebarLinks notifications={notifications} />
          </ul>
        </nav>

        <div className="flex-shrink-0 p-4">
          <ExpandButton controlId={sidebarId} />
        </div>
        <ProfileLink
          className="flex-shrink-0"
          path={Path.Settings}
          label="Settings"
        />
      </div>
    </div>
  );
};
