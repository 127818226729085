import { User } from "api/types";
import { RecordingType } from "../../types";
import { displayName } from "utils/user";

export const getCallTitle = (
  agents: User[],
  recordingType: RecordingType
): string => {
  const names = agents.map(displayName);
  let agentNames = "";
  if (names.length === 0) {
    agentNames = "Somebody";
  } else if (names.length === 1) {
    agentNames = names[0];
  } else if (names.length === 2) {
    agentNames = `${names[0]} and ${names[1]}`;
  } else {
    agentNames = `${names.slice(0, -1).join(", ")}, and ${
      names[names.length - 1]
    }`;
  }

  if (recordingType === RecordingType.Inbound) {
    return `${agentNames} took a call`;
  }
  return `${agentNames} made a call`;
};
