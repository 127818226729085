import { PhoneIcon } from "@heroicons/react/24/solid";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { CallQueueItem } from "api/types";
import { Badge, BadgeVariant } from "componentsV2/Badges";
import { FacilityInfo } from "modulesV2/FacilityInfo";
import { getElapsedTimeMinutes } from "utils/date";
import { displayName } from "utils/facility";
import { formatE164ToDisplay } from "utils/phoneNumber";
import { getTenantDisplayName } from "utils/tenant";
import {
  getCallQueueItemSkillName,
  isCallQueueItemPhoneCall,
} from "../../utils";

type NextCallQueueItemProps = {
  item: CallQueueItem;
};

export const NextCallQueueItem = ({ item }: NextCallQueueItemProps) => {
  const [facilityInfoOpen, setFacilityInfoOpen] = useState(false);

  const isPhoneCall = isCallQueueItemPhoneCall(item);
  const Icon = isPhoneCall ? PhoneIcon : BuildingOfficeIcon;
  const skillRequirement = getCallQueueItemSkillName(item);
  const currentFacility = item?.facility;

  const [waitingTime, setWaitingTime] = useState(0);

  useEffect(
    function handleItemUpdated() {
      // Set up an interval to increment the time by one minute every 60 seconds
      setWaitingTime(getElapsedTimeMinutes(item.timeCreated));
      const updateWaitingTimeInterval = setInterval(() => {
        setWaitingTime((prevTime) => prevTime + 1);
      }, 60000);

      return () => {
        clearInterval(updateWaitingTimeInterval);
      };
    },
    [item]
  );

  const renderFacilityName = () => {
    // Regular facility (facility defined):
    // Render display name as a button to open info modal
    if (currentFacility) {
      return (
        <button
          type="button"
          onClick={() => setFacilityInfoOpen(true)}
          className="cursor-pointer underline"
        >
          {displayName(currentFacility)}
        </button>
      );
    }

    // OrgPhone (phoneCall.facilityPhoneNumber defined):
    // Render "Unknown - (###) ###-####" as text
    if (item.phoneCall?.facilityPhoneNumber) {
      return `Unknown - ${formatE164ToDisplay(
        item.phoneCall.facilityPhoneNumber
      )}`;
    }

    // Fallback:
    // Render "Unknown" as text
    return "Unknown";
  };

  const renderTenantInfo = () => {
    if (!isPhoneCall) return null;

    const tenantName = getTenantDisplayName(item.tenant);
    const fromPhoneNumber = item.phoneCall.fromPhoneNumber;

    return (
      <>
        <div>{tenantName}</div>
        <span>•</span>
        {fromPhoneNumber && (
          <>
            <div>{formatE164ToDisplay(fromPhoneNumber)}</div>
            <span>•</span>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <div className="flex gap-3 items-center">
        <Icon
          className="flex-shrink-0 h-6 w-6 text-gray-900"
          aria-hidden="true"
        />
        <div className="font-semibold text-gray-500">Next call:</div>
        <div className="font-semibold">{renderFacilityName()}</div>
        {skillRequirement && (
          <Badge text={skillRequirement} variant={BadgeVariant.RED} />
        )}
        <div className="flex flex-row items-center gap-1 text-gray-500">
          {renderTenantInfo()}
          <div className="text-red-600 italic">
            {`Waiting ${waitingTime} Minute${waitingTime === 1 ? "" : "s"}`}
          </div>
        </div>
      </div>
      {currentFacility && (
        <FacilityInfo
          facility={currentFacility}
          modal
          modalActive={facilityInfoOpen}
          handleCloseModal={() => setFacilityInfoOpen(false)}
        />
      )}
    </>
  );
};
