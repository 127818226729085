import React, { useEffect, useState, forwardRef } from "react";
import { useSelector } from "react-redux";
import { agentSelector } from "state/agentSlice";
import { handlePusherResourceEvent, pusher } from "services/Pusher";
import { Audio } from "componentsV2/Audio";
import { ExpandableText } from "componentsV2/ExpandableText";
import { LoadingIndicator } from "componentsV2/LoadingIndicator";
import {
  CallTranscription,
  FeatureName,
  TranscriptStatus,
  VoicemailCategory,
} from "api/types";
import { isOrganizationFeatureEnabled } from "utils/organization";
import { normalizeUrl, removeSignatureFromUrl } from "utils/task";
import { FaRobot } from "react-icons/fa";

export enum VoicemailVariant {
  REGULAR = "REGULAR",
  SMALL = "SMALL",
}

const voicemailClasses: Record<
  VoicemailVariant,
  {
    transcriptionClasses?: string;
    audioClasses?: string;
  }
> = {
  [VoicemailVariant.REGULAR]: {},
  [VoicemailVariant.SMALL]: {
    transcriptionClasses: "bg-gray-50 text-xs p-2",
    audioClasses: "flex justify-center",
  },
};

const getVoicemailClasses = (variant: VoicemailVariant) => {
  const { transcriptionClasses, audioClasses } = voicemailClasses[variant];
  return {
    transcriptionClasses,
    audioClasses,
  };
};

interface VoicemailProps {
  recordingUrl: string;
  variant?: VoicemailVariant;
  title?: string;
  onCallTranscriptionCreated?: (
    recordingUrl: string,
    callTranscription: CallTranscription
  ) => void;
  callTranscript?: CallTranscription;
}
const categoryMapping: Partial<Record<VoicemailCategory, { text: string }>> = {
  [VoicemailCategory.PAYMENT_SEND_LINK]: {
    text: "Payment link sent",
  },
  [VoicemailCategory.MOVE_OUT_LINK]: {
    text: "Move out link sent",
  },
};

export const Voicemail = forwardRef<HTMLDivElement, VoicemailProps>(
  (
    {
      recordingUrl,
      variant = VoicemailVariant.REGULAR,
      title = "",
      onCallTranscriptionCreated,
      callTranscript,
    },
    ref
  ) => {
    const agent = useSelector(agentSelector);
    const isVoicemailTranscriptionEnabled =
      !!agent.organization &&
      isOrganizationFeatureEnabled(agent.organization, [
        FeatureName.VoicemailTranscription,
      ]);
    const [isTimeoutReached, setIsTimeoutReached] = useState(false);
    const callTranscriptIsComplete = !!(
      callTranscript?.status &&
      [TranscriptStatus.COMPLETED, TranscriptStatus.NOT_AVAILABLE].includes(
        callTranscript.status
      ) &&
      callTranscript?.text &&
      callTranscript?.category
    );

    useEffect(
      function setupCallTranscriptionUpdatedListener() {
        const pushChannelPrefix = agent.organization?.pushChannelPrefix ?? "";

        if (
          !pushChannelPrefix ||
          !recordingUrl ||
          !isVoicemailTranscriptionEnabled ||
          callTranscriptIsComplete ||
          !onCallTranscriptionCreated ||
          isTimeoutReached
        ) {
          return;
        }

        const timeoutId = setTimeout(() => {
          setIsTimeoutReached(true);
        }, 30000);

        const transcriptUpdatedChannelName =
          pushChannelPrefix + "CallTranscription_updated";

        const transcriptUpdatedChannels = pusher.subscribe(
          transcriptUpdatedChannelName
        );

        const handleTranscriptEvent = (data: CallTranscription) => {
          const unsignedUrl = removeSignatureFromUrl(recordingUrl);
          const normalizedDataUrl = normalizeUrl(data.recordingUrl);
          if (normalizedDataUrl === unsignedUrl) {
            onCallTranscriptionCreated(unsignedUrl, data);
          }
        };

        transcriptUpdatedChannels.forEach((transcriptCreatedChannel) => {
          transcriptCreatedChannel.bind("model_updated", (event: unknown) =>
            handlePusherResourceEvent(event, handleTranscriptEvent)
          );
        });

        return () => {
          clearTimeout(timeoutId);
          transcriptUpdatedChannels.forEach((transcriptCreatedChannel) => {
            transcriptCreatedChannel.unbind("model_updated");
          });
          pusher.unsubscribe(transcriptUpdatedChannelName);
        };
      },
      [
        recordingUrl,
        isVoicemailTranscriptionEnabled,
        callTranscriptIsComplete,
        isTimeoutReached,
      ]
    );

    const { transcriptionClasses, audioClasses } = getVoicemailClasses(variant);

    const renderTranscription = () => {
      const callTranscriptNotAvailable =
        !callTranscript?.status ||
        callTranscript.status === TranscriptStatus.NOT_AVAILABLE;
      const callTranscriptCompleteWithNoText =
        callTranscript?.status === TranscriptStatus.COMPLETED &&
        callTranscript.text === "";

      if (
        !isVoicemailTranscriptionEnabled ||
        callTranscriptNotAvailable ||
        callTranscriptCompleteWithNoText
      ) {
        return null;
      }

      if (callTranscript.text) {
        return (
          <div className={transcriptionClasses}>
            <ExpandableText
              text={callTranscript.text}
              title={title}
              maxChars={250}
            />
          </div>
        );
      }

      if (!isTimeoutReached) {
        return (
          <div className="text-xs text-gray-700 leading-4 flex items-center">
            <LoadingIndicator className="!h-4 !w-4 text-gray-400  mr-2" />
            <span className="text-gray-500 font-semibold">
              Transcribing voicemail...
            </span>
          </div>
        );
      }
      return null;
    };

    const renderCategory = () => {
      if (variant === VoicemailVariant.SMALL || !callTranscript?.category) {
        return null;
      }
      const mappedValues = categoryMapping[callTranscript.category];
      if (!mappedValues) {
        return null;
      }

      return (
        <div className="flex items-center space-x-2">
          <FaRobot className="w-4 h-4 text-blue-500" />
          <span className="text-xs font-bold text-blue-500">
            AI Automation:
          </span>
          <span className="text-xs text-blue-500">{mappedValues.text}</span>
        </div>
      );
    };

    return recordingUrl ? (
      <div ref={ref} className="space-y-2">
        <Audio src={recordingUrl} className={audioClasses} />
        {renderTranscription()}
        {renderCategory()}
      </div>
    ) : null;
  }
);

Voicemail.displayName = "Voicemail";
