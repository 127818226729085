import cn from "classnames";
import React, { useEffect, useState } from "react";

export interface ProgressBarProps {
  value: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ value }) => {
  const [width, setWidth] = useState(0);

  useEffect(
    function updateWidthState() {
      const clampedValue = Math.min(100, Math.max(0, value));
      setWidth(clampedValue);
    },
    [value]
  );

  let colorClasses = "";
  if (value >= 80) {
    colorClasses = "from-teal-400/20 to-green-400";
  } else if (value >= 40) {
    colorClasses = "from-cyan-400/20 to-blue-400";
  } else {
    colorClasses = "from-pink-400/20 to-red-400";
  }

  return (
    <div className="overflow-hidden rounded-full bg-gray-100">
      <div
        className={cn(
          "h-1.5 rounded-full bg-gradient-to-r transition-all duration-500",
          colorClasses
        )}
        style={{ width: `${width}%` }}
      />
    </div>
  );
};
