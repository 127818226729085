import { Disclosure, DisclosureProps } from "@headlessui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import React, { MutableRefObject } from "react";
import { Card, CardProps } from "./Card";

// NOTE: not exported from @headlessui/react
interface DisclosureRenderPropArg {
  open: boolean;
  close(
    focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null>
  ): void;
}

export interface CardExpandableProps
  extends Pick<DisclosureProps<"div">, "defaultOpen">,
    Pick<CardProps, "children" | "heading"> {
  previewContent?: React.ReactNode;
}

export const CardExpandable: React.FC<CardExpandableProps> = ({
  heading,
  children,
  previewContent,
  ...disclosureProps
}) => {
  const renderHeading = ({ open }: DisclosureRenderPropArg) => {
    const HeadingIcon = open ? ChevronDownIcon : ChevronRightIcon;
    return (
      <Disclosure.Button
        as="div"
        className="flex justify-between gap-2 cursor-pointer"
      >
        <span>{heading}</span>
        <button
          type="button"
          aria-label={open ? "Collapse container" : "Expand container"}
        >
          <HeadingIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
        </button>
      </Disclosure.Button>
    );
  };

  const renderPreviewContent = ({ open }: DisclosureRenderPropArg) => {
    const hasPreviewContent = !!previewContent;

    if (!open && hasPreviewContent) {
      return previewContent;
    }

    return null;
  };

  return (
    <Disclosure as="div" {...disclosureProps}>
      {(renderProps) => (
        <Card heading={renderHeading(renderProps)}>
          <Disclosure.Panel>{children}</Disclosure.Panel>
          {renderPreviewContent(renderProps)}
        </Card>
      )}
    </Disclosure>
  );
};
