import { getOrganizationFacilities } from "api/facilities";
import { getAgent, getAgentFacilities, getOrganizationAgents } from "api/users";
import { fetchAllTasks, fetchCallQueue } from "services/tasks";
import { updateAgent, updateAgentFacilities } from "state/agentSlice";
import { updateFacilities } from "state/facilitiesSlice";
import { loadLocalStorage } from "state/sessionSlice";
import { updateVirtualManagers } from "state/virtualManagersSlice";
import { AppDispatch } from "store";
import { updateAgent as updateAgentApi } from "api/users";
import { Status } from "api/types";
import { isUserOffline } from "utils/user";

export const fetchFacilities = async (dispatch: AppDispatch) => {
  const res = await getOrganizationFacilities();
  dispatch(updateFacilities(res.data.results));
  return res.data.results;
};

export const fetchAgent = async (dispatch: AppDispatch) => {
  const res = await getAgent();
  const userIsOffline = await isUserOffline(res.data.id);
  if (userIsOffline) {
    const resUpdate = await updateAgentApi({ availability: Status.Away });
    dispatch(updateAgent(resUpdate.data));
    return resUpdate.data;
  } else {
    dispatch(updateAgent(res.data));
    return res.data;
  }
};

export const fetchAgentFacilities = async (dispatch: AppDispatch) => {
  const res = await getAgentFacilities();
  dispatch(updateAgentFacilities(res.data));
  return res.data;
};

export const fetchVirtualManagers = async (dispatch: AppDispatch) => {
  const res = await getOrganizationAgents();
  dispatch(updateVirtualManagers(res.data.results));
  return res.data.results;
};

export const refresh = async (dispatch: AppDispatch) => {
  Promise.all([
    dispatch(fetchFacilities),
    dispatch(fetchAgent),
    dispatch(fetchAgentFacilities),
    dispatch(fetchVirtualManagers),
    dispatch(fetchAllTasks),
    dispatch(fetchCallQueue),
  ]);
  // Load Webex information from local storage
  dispatch(loadLocalStorage());
};
