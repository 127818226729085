import React from "react";

export interface CardProps {
  children?: React.ReactNode;
  heading?: React.ReactNode;
}

export const Card: React.FC<CardProps> = ({ children, heading }) => {
  const renderContent = () => {
    const hasHeading = !!heading;

    if (hasHeading) {
      return (
        <>
          <div className="font-medium px-4 py-5 sm:px-6 group">{heading}</div>
          <div className="px-4 pb-5 pt-2 sm:px-6 sm:pb-6 empty:!p-0">
            {children}
          </div>
        </>
      );
    }

    return <div className="px-4 py-5 sm:p-6">{children}</div>;
  };

  return (
    <div className="rounded-lg sm:rounded-2xl bg-white shadow border border-gray-200 text-gray-900 text-sm font-normal">
      {renderContent()}
    </div>
  );
};
