import { AppDispatch, AppStore } from "store";
import {
  updateTaskAssignments,
  tasksSelector,
  removeByTaskAssignmentId,
  updateTaskAssignment,
  updateAllTaskAssignments,
  tasksLocalStateSelector,
} from "state/tasksSlice";
import { getTaskAssignments } from "api/users";
import {
  updateTaskAssignment as updateTaskAssignmentApi,
  getOrganizationAcceptedTaskAssignments,
  getCallQueue,
} from "api/tasks";
import { RealtimeTaskStatus, TaskAssignment } from "api/types";
import { SnackbarType } from "components/Snackbar";
import { connectToVmosCall } from "./connectCalls";
import { updateCallQueueData } from "state/callQueueSlice";
import { displayName } from "utils/facility";

export const fetchTaskAssignments = async (dispatch: AppDispatch) => {
  const res = await getTaskAssignments();
  dispatch(updateTaskAssignments(res.data.results));
};

export const fetchCallQueue = async (dispatch: AppDispatch) => {
  const res = await getCallQueue();
  dispatch(updateCallQueueData(res.data));
};

// This is fetching all ACCEPTED tasks for all agents
// to be displayed in the ActiveFacilities module
export const fetchAllTasks = async (dispatch: AppDispatch) => {
  const res = await getOrganizationAcceptedTaskAssignments();
  dispatch(updateAllTaskAssignments(res.data.results));
  return res.data.results;
};

export const maybeAutopassTaskAssignment = async (
  id: number,
  store: AppStore,
  onAutoPass?: (ta: TaskAssignment) => void
) => {
  const tasks = tasksSelector(store.getState());
  const filteredTasks = tasks.filter((task) => task.id === id);
  if (filteredTasks.length === 0) {
    return;
  }
  // There should only be one task with a specific
  // id, so we take the first element.
  const task = filteredTasks[0];
  if (task.status !== RealtimeTaskStatus.Created) {
    return;
  }
  const res = await updateTaskAssignmentApi(task.id, {
    status: RealtimeTaskStatus.Passed,
  });

  const taskAssignment = res.data;
  if (taskAssignment.status === RealtimeTaskStatus.Passed) {
    store.dispatch(removeByTaskAssignmentId(task.id));
  }

  if (typeof onAutoPass === "function") {
    onAutoPass(taskAssignment);
  }
};

export const acceptTaskAssignment = async (
  id: number,
  store: AppStore,
  snacks: SnackbarType
) => {
  const res1 = await updateTaskAssignmentApi(id, {
    status: RealtimeTaskStatus.Accepted,
  });
  const taskAssignment = res1.data;
  if (taskAssignment.status !== RealtimeTaskStatus.Accepted) {
    throw new Error(
      `Failed to accept task (RealtimeTaskStatus = ${taskAssignment.status}`
    );
  }
  store.dispatch(updateTaskAssignment(taskAssignment));

  // If a call is already active (Twilio or WebEx), don't dial in.
  const localState = tasksLocalStateSelector(store.getState());
  if (
    localState.dialedInTaskAssignmentId != null ||
    localState.dialedInAsyncTaskId != null ||
    localState.dialedInPhoneNumber != null
  ) {
    return;
  }

  await connectToVmosCall(taskAssignment, store, snacks);
};

export const passTaskAssignment =
  (id: number) => async (dispatch: AppDispatch) => {
    await updateTaskAssignmentApi(id, {
      status: RealtimeTaskStatus.Passed,
    });
    dispatch(removeByTaskAssignmentId(id));
  };

export const dismissTaskAssignment =
  (id: number) => async (dispatch: AppDispatch) => {
    await updateTaskAssignmentApi(id, {
      status: RealtimeTaskStatus.Dismissed,
    });
    dispatch(removeByTaskAssignmentId(id));
  };

export const reassignTaskAssignment =
  (id: number, userId: number) => async (dispatch: AppDispatch) => {
    await updateTaskAssignmentApi(id, {
      status: RealtimeTaskStatus.Reassigned,
      reassignedToUrl: `/v1/users/${userId}`,
    });
    dispatch(removeByTaskAssignmentId(id));
  };

export const acceptCall = async (
  task: TaskAssignment,
  store: AppStore,
  snacks: SnackbarType
) => {
  try {
    await acceptTaskAssignment(task.id, store, snacks);
    snacks.showSuccessSnack(
      `Connected to ${displayName(task.realtimeTask.facility)}`
    );
  } catch (e) {
    if (e instanceof Error) {
      snacks.showErrorSnack(e.message);
    } else {
      snacks.showErrorSnack("An error occurred while accepting call");
    }
  }
};
