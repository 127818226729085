import {
  AsyncTaskV1,
  AsyncTaskAssignmentType,
  AsyncTaskResourceType,
  CallStatus,
  CustomerInteractionResolution,
  LeadFollowUpTask,
  MessageTask,
  MissedCallFollowUpTask,
  SkillRequirement,
  MoveOutTask,
} from "api/types";
import { createMockConversation } from "./conversations";
import { openFacility } from "./facilities";
import { testTenant } from "./tenants";
import { mockUser } from "./users";

const asyncTaskV1Fields = {
  id: 306,
  url: "/v1/async-tasks/306",
  facility: openFacility,
  facilityId: openFacility.id,
  timeCreated: "2023-03-24T21:51:09.099257Z",
  resolution: CustomerInteractionResolution.NotSet,
  additionalNotes: "",
  asyncTaskAssignments: [
    {
      id: 243,
      url: "/v1/async-task-assignments/243",
      status: AsyncTaskAssignmentType.Revisit,
      notes: "",
      asyncTaskId: 306,
      assignee: mockUser,
      assigneeId: mockUser.id,
      timeCreated: "2023-03-24T21:51:32.017925Z",
      timeRevisit: "2023-03-24T21:51:38.357696Z",
      timeCompleted: "",
    },
  ],
  phoneCall: {
    id: 407,
    url: "/v1/phone-calls/407",
    twilioConferenceName: "4cc81631-68cf-3f03-8077-0eb3c5a52020",
    twilioConferenceSid: "CFb034cc165cb1b8c7a0ed82b8a21280a6",
    twilioCallSid: "CAf60f367bee75a923e835d6c54cb67c42",
    timeCreated: "2023-03-24T21:50:58.439752Z",
    status: CallStatus.Ended,
    fromPhoneNumber: "+12247171960",
    facilityPhoneNumber: "+12059973426",
    voicemailUrl: undefined,
  },
  isCompleted: false,
  tenant: testTenant,
  tenantId: testTenant.id,
  skillRequirements: [],
};

export const createMockSkillRequirement = (
  fields: Partial<SkillRequirement> = {}
): SkillRequirement => ({
  id: 0,
  taskId: 0,
  skillId: 0,
  skill: { name: "string" },
  weight: 0,
  ...fields,
});

export const createMockAsyncTaskV1 = (
  fields: Partial<AsyncTaskV1> = {}
): AsyncTaskV1 => ({
  ...asyncTaskV1Fields,
  resourcetype: AsyncTaskResourceType.AsyncTask,
  ...fields,
});

export const createMockMissedCallFollowUpTask = (
  fields: Partial<MissedCallFollowUpTask> = {}
): MissedCallFollowUpTask => ({
  ...asyncTaskV1Fields,
  resourcetype: AsyncTaskResourceType.MissedCallFollowUpTask,
  customerInteractionResult: CustomerInteractionResolution.NotSet,
  caller: testTenant,
  callerId: testTenant.id,
  missedCalls: [],
  ...fields,
});

export const createMockLeadFollowUpTask = (
  fields: Partial<LeadFollowUpTask> = {}
): LeadFollowUpTask => ({
  ...asyncTaskV1Fields,
  additionalNotes:
    "Phone/walk-in reserved: 1 day after missed reservation - task",
  resourcetype: AsyncTaskResourceType.LeadFollowUpTask,
  customerInteractionResult: CustomerInteractionResolution.NotSet,
  leadTenant: testTenant,
  leadTenantId: testTenant.id,
  ...fields,
});

export const createMockMessageTask = (
  fields: Partial<MessageTask> = {}
): MessageTask => ({
  ...asyncTaskV1Fields,
  resourcetype: AsyncTaskResourceType.MessageTask,
  customerInteractionResult: CustomerInteractionResolution.NotSet,
  customer: testTenant,
  customerId: testTenant.id,
  conversation: createMockConversation({
    customer: fields?.customer ?? testTenant,
    customerId: fields?.customerId ?? testTenant.id,
    facility: fields?.facility ?? openFacility,
    facilityId: fields?.facilityId ?? openFacility.id,
  }),
  messageTaskMessages: [],
  ...fields,
});

export const createMockMoveOutTask = (
  fields: Partial<MoveOutTask> = {}
): MoveOutTask => ({
  ...asyncTaskV1Fields,
  resourcetype: AsyncTaskResourceType.MoveOutTask,
  customerInteractionResult: CustomerInteractionResolution.NotSet,
  tenant: { ...testTenant, isActive: true },
  tenantId: testTenant.id,
  pmsLedgerId: "123",
  unitName: "202",
  unitImages: [
    "https://picsum.photos/id/1/600/400",
    "https://picsum.photos/id/2/600/400",
    "https://picsum.photos/id/3/600/400",
  ],
  formSubmission: {
    "reason-to-leave": {
      value: "Price",
      label: "Why did you choose to leave?",
    },
    "prevent-moving": {
      value: "More discounts for existing customers",
      label:
        "Was there anything we could have done to prevent you from moving?",
    },
    "rent-again": {
      value: "No",
      label: "Would you rent from us again?",
    },
    "make-experience-better": {
      value: "",
      label:
        "If there was one thing we could have done to make your experience better, what would that have been?",
    },
    "comments-overall-experience": {
      value:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.\nIubet igitur nos Pythius Apollo noscere nosmet ipsos.\nQui autem de summo bono dissentit de tota philosophiae ratione dissentit.",
      label: "Comments regarding your overall experience.",
    },
    contact: {
      value: "Yes",
      label: "May we contact you?",
    },
  },
  ...fields,
});

export const mockAsyncTask = createMockAsyncTaskV1();
