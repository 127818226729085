import React, { useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { AsyncTaskAssignment } from "api/types";
import {
  getAsyncTaskAssignmentStatusText,
  getAsyncTaskAssignmentStatusTime,
} from "utils/asyncTask";
import { formatShortDate } from "utils/date";
import { debounce } from "utils/debounce";
import { displayName } from "utils/user";

interface TaskNotesToggleProps {
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const TaskNotesToggle: React.FC<TaskNotesToggleProps> = ({
  active,
  setActive,
}) => (
  <button
    className="text-xs font-medium text-navy-700 underline"
    type="button"
    onClick={() => setActive(!active)}
  >
    {active ? "See Less" : "See More"}
  </button>
);

interface TaskHistoryItemProps {
  asyncTaskAssignment: AsyncTaskAssignment;
}

const TaskHistoryItem: React.FC<TaskHistoryItemProps> = ({
  asyncTaskAssignment,
}) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const [showFullNote, setShowFullNote] = useState(false);
  const outerRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLSpanElement>(null);

  const checkIfTruncated = () => {
    if (outerRef.current && innerRef.current) {
      setIsTruncated(
        innerRef.current.offsetHeight > outerRef.current.offsetHeight
      );
    }
  };

  const dbCheckIfTruncated = useCallback(debounce(checkIfTruncated, 250), []);

  useEffect(() => {
    checkIfTruncated();
    window.addEventListener("resize", dbCheckIfTruncated);
    return () => {
      window.removeEventListener("resize", dbCheckIfTruncated);
    };
  }, [asyncTaskAssignment.notes, dbCheckIfTruncated]);

  const statusLabel = `Marked as ${getAsyncTaskAssignmentStatusText(
    asyncTaskAssignment.status
  )}`;
  const assigneeLabel = displayName(asyncTaskAssignment.assignee);
  const statusTime = getAsyncTaskAssignmentStatusTime(asyncTaskAssignment);
  const timestampLabel = formatShortDate(new Date(statusTime));
  const hasNotes =
    asyncTaskAssignment.notes && asyncTaskAssignment.notes.length > 0;

  return (
    <div className="text-xs leading-4 text-black">
      <div className="flex justify-between gap-2">
        <div>
          <strong>{statusLabel}</strong> by {assigneeLabel}
        </div>
        <div>{timestampLabel}</div>
      </div>
      {hasNotes && (
        <div className="flex justify-between gap-2 mt-2">
          <div
            ref={outerRef}
            className={clsx("flex-1 max-w-lg", {
              "line-clamp-1": !showFullNote,
            })}
          >
            <span
              ref={innerRef}
              className="whitespace-pre-line break-words overflow-hidden"
              onClick={showFullNote ? undefined : () => setShowFullNote(true)}
            >
              {asyncTaskAssignment.notes}
            </span>
            <span>{/* For inline space between notes and button */} </span>
            {isTruncated && showFullNote && (
              <TaskNotesToggle
                active={showFullNote}
                setActive={setShowFullNote}
              />
            )}
          </div>
          {isTruncated && !showFullNote && (
            <div className="shrink-0">
              <TaskNotesToggle
                active={showFullNote}
                setActive={setShowFullNote}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

interface TaskHistoryProps {
  asyncTaskAssignments: AsyncTaskAssignment[];
}

export const testIds = {
  outer: "task-history-outer",
};

export const TaskHistory: React.FC<TaskHistoryProps> = ({
  asyncTaskAssignments,
}) => (
  <ul className="space-y-4" data-testid={testIds.outer}>
    {asyncTaskAssignments.map((ata) => (
      <li key={ata.id}>
        <TaskHistoryItem asyncTaskAssignment={ata} />
      </li>
    ))}
  </ul>
);
