import { FeatureName, Organization } from "api/types";

/**
 * Checks if any of the specified features are enabled for the given organization.
 *
 * @param {Pick<Organization, "features">} organization - The organization object containing the features.
 * @param {FeatureName[]} featureNames - The list of feature names to check.
 * @returns {boolean} - Returns true if any of the specified features are enabled, otherwise false.
 */
export function isOrganizationFeatureEnabled(
  organization: Pick<Organization, "features">,
  featureNames: FeatureName[]
): boolean {
  if (organization.features.length === 0) return false;

  return organization.features.some((feature) =>
    featureNames.includes(feature.name)
  );
}
