import React from "react";
import { AsyncTaskResourceType, FeatureName } from "api/types";
import { useSelector } from "hooks/redux";
import { agentSelector } from "state/agentSlice";
import { isOrganizationFeatureEnabled } from "utils/organization";
import { Actions } from "./components/Actions";
import { Filters } from "./components/Filters";
import { RefreshBanner } from "./components/RefreshBanner";
import { TaskList } from "./components/TaskList";
import { ASYNC_TASK_FILTERS } from "./constants";
import { TasksProvider } from "./contexts/TasksContext";

export const testIds = {
  outer: "tasks-outer",
};

export const Tasks = () => {
  const agent = useSelector(agentSelector);
  const isSendMoveOutLinkEnabled =
    !!agent.organization &&
    isOrganizationFeatureEnabled(agent.organization, [
      FeatureName.SendMoveOutLink,
    ]);

  const activeFilters = ASYNC_TASK_FILTERS.filter((filter) => {
    switch (filter.resourcetype) {
      case AsyncTaskResourceType.MoveOutTask:
        return isSendMoveOutLinkEnabled;
      default:
        return true;
    }
  });

  return (
    <TasksProvider>
      <div className="grid gap-4" data-testid={testIds.outer}>
        <Actions />
        <Filters filters={activeFilters} />
        <RefreshBanner />
        <TaskList />
      </div>
    </TasksProvider>
  );
};
