import React, { useState } from "react";
import { Facility } from "api/types";
import { FacilityInfo } from "modulesV2/FacilityInfo";
import { displayName } from "utils/facility";

type FacilityButtonProps = {
  facility?: Facility;
};

export const FacilityButton = ({ facility }: FacilityButtonProps) => {
  const [facilityInfoModalOpen, setFacilityInfoModalOpen] = useState(false);
  const facilityDisplayName = displayName(facility);

  if (!facility) {
    return (
      <span className="text-gray-500 text-xs font-normal">
        {facilityDisplayName}
      </span>
    );
  }

  return (
    <>
      <button
        type="button"
        className="text-gray-500 text-xs font-normal hover:underline"
        onClick={() => setFacilityInfoModalOpen(true)}
      >
        {facilityDisplayName}
      </button>
      <FacilityInfo
        facility={facility}
        modal
        modalActive={facilityInfoModalOpen}
        handleCloseModal={() => setFacilityInfoModalOpen(false)}
      />
    </>
  );
};
