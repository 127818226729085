import React, { useEffect, useRef, useState } from "react";
import { Button, ButtonVariant } from "componentsV2/Button";
import { FeedbackInputName } from "./types";
import { RadioGroup } from "componentsV2/Inputs";
import { FEEDBACK_VALUES } from "./constants";
import { useSnackbar } from "modulesV2/Snackbar";
import { FormModal, FormModalProps } from "./FormModal";

type RatingModalProps = Pick<
  FormModalProps,
  "active" | "handleClose" | "title" | "handleSubmit"
> & {
  radioGroups: { label: string; name: FeedbackInputName }[];
  defaultCheckedValues: Record<string, string>;
};

export const RatingModal = ({
  active,
  handleClose,
  title,
  handleSubmit,
  radioGroups,
  defaultCheckedValues,
}: RatingModalProps) => {
  const initialFocusRef = useRef<HTMLButtonElement>(
    null
  ) as React.MutableRefObject<HTMLButtonElement>;
  const hasInitialValue = Object.values(defaultCheckedValues).some(
    (value) => value !== ""
  );
  const [hasChanged, setHasChanged] = useState(hasInitialValue);
  useEffect(
    function resetHasChanged() {
      if (!active) {
        setHasChanged(hasInitialValue);
      }
    },
    [active]
  );

  const { showErrorSnack } = useSnackbar();

  const handleSubmitWithHasChangedCheck = (data: FormData) => {
    if (!hasChanged) {
      showErrorSnack("Please select a rating before submitting");
      return;
    }
    handleSubmit(data);
  };

  const actions = (
    <>
      <Button
        ref={initialFocusRef}
        type="button"
        onClick={handleClose}
        variant={ButtonVariant.WHITE}
      >
        Cancel
      </Button>
      <Button type="submit" disabled={!hasChanged}>
        Next
      </Button>
    </>
  );

  return (
    <FormModal
      active={active}
      handleClose={handleClose}
      handleSubmit={handleSubmitWithHasChangedCheck}
      title={title}
      actions={actions}
    >
      {radioGroups.map(({ label, name }) => (
        <RadioGroup
          key={name}
          name={name}
          label={
            <span
              className="text-sm font-normal text-gray-500 [&_strong]:font-medium [&_strong]:text-gray-900"
              dangerouslySetInnerHTML={{ __html: label }}
            />
          }
          options={FEEDBACK_VALUES}
          onChange={() => setHasChanged(true)}
          defaultCheckedValue={defaultCheckedValues[name]}
        />
      ))}
    </FormModal>
  );
};
