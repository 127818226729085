export enum Path {
  Calls = "/calls",
  Tasks = "/tasks",
  Facilities = "/facilities",
  VirtualManagers = "/virtual-managers",
  Comms = "/comms",
  Settings = "/settings",
  Recordings = "/recordings/:id",
  InboundRecording = "/recordings/inbound/:id",
  OutboundRecording = "/recordings/outbound/:id",
  OAuthRedirect = "/oauth/user/redirectCallback",
}
