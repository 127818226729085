import cn from "classnames";
import React from "react";
import { Audio } from "componentsV2/Audio";
import { LoadingIndicator } from "componentsV2/LoadingIndicator";
import { OnMediaCanPlayThroughEventHandler } from "../../types";
import { MediaType } from "./types";

type ContainerProps = {
  children?: React.ReactNode;
  className?: string;
  aspectClassName?: string;
  bgClassName?: string;
};

export const Container = ({
  children,
  className,
  aspectClassName = "aspect-video",
  bgClassName = "bg-navy-50",
}: ContainerProps) => (
  <div
    className={cn(
      "relative rounded-2xl overflow-hidden border border-black/10 flex items-center justify-center px-2 py-20",
      aspectClassName,
      bgClassName,
      className
    )}
  >
    {children}
  </div>
);

type MediaPlayerProps = {
  src?: string;
  type: MediaType;
  onCanPlayThrough?: OnMediaCanPlayThroughEventHandler;
  loading?: boolean;
};

export const MediaPlayer = ({
  src,
  type,
  onCanPlayThrough,
  loading,
}: MediaPlayerProps) => {
  if (src) {
    switch (type) {
      case MediaType.Audio:
        return (
          <Container
            aspectClassName="aspect-square sm:aspect-video md:aspect-square lg:aspect-video"
            bgClassName="bg-navy-900"
          >
            <div className="flex flex-col items-center gap-2">
              <img className="block w-1/2 h-auto" src="/soundwave.svg" alt="" />
              <span className="text-sm text-white">
                Audio-only call recording
              </span>
            </div>
            <Audio
              src={src}
              className="absolute bottom-2 left-2 right-2"
              audioClassName="w-full"
              onCanPlayThrough={onCanPlayThrough}
            />
          </Container>
        );
      default:
        return (
          <Container>
            <span className="text-sm text-gray-400">
              Unsupported media type
            </span>
          </Container>
        );
    }
  }

  return (
    <Container>
      <div className="text-sm text-gray-400">
        {loading ? (
          <div className="flex items-center gap-2">
            <LoadingIndicator />
            <div>Loading call recording...</div>
          </div>
        ) : (
          <div>Call recording not available</div>
        )}
      </div>
    </Container>
  );
};
