import React, { useRef } from "react";
import { TextArea } from "componentsV2/Inputs";
import { Button, ButtonVariant } from "componentsV2/Button";
import { FormModal, FormModalProps } from "./FormModal";
import { FeedbackInputName } from "./types";

const MAX_CHAR_LIMIT = 500;

type DetailsModalProps = Pick<
  FormModalProps,
  "active" | "handleClose" | "title" | "handleSubmit"
> & {
  value: string;
  handleChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  handleBack: () => void;
  handleSubmit: (data: FormData) => Promise<void>;
  detailsInputName: FeedbackInputName;
  isSubmitting?: boolean;
};

export const DetailsModal = ({
  active,
  handleClose,
  title,
  value,
  handleChange,
  handleBack,
  handleSubmit,
  detailsInputName,
  isSubmitting,
}: DetailsModalProps) => {
  const initialFocusRef = useRef<HTMLButtonElement>(
    null
  ) as React.MutableRefObject<HTMLButtonElement>;

  const actions = (
    <>
      <Button
        ref={initialFocusRef}
        type="button"
        onClick={handleBack}
        variant={ButtonVariant.WHITE}
      >
        Go back
      </Button>
      <Button type="submit" loading={isSubmitting}>
        Submit
      </Button>
    </>
  );

  const onTextAreaChange: React.ChangeEventHandler<HTMLTextAreaElement> = (
    e
  ) => {
    const currentTargetValue = e.currentTarget.value;
    if (currentTargetValue.length > MAX_CHAR_LIMIT) {
      e.preventDefault();
      return;
    }
    handleChange(e);
  };

  return (
    <FormModal
      active={active}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      title={title}
      actions={actions}
    >
      <div>
        <p className="text-sm text-gray-500 font-normal mb-6">
          If you’d like, please share more details here (optional):
        </p>
        <TextArea
          name={detailsInputName}
          value={value}
          onChange={onTextAreaChange}
          rows={5}
        />
        <div className="text-sm mt-2 text-gray-500">
          {value.length}/{MAX_CHAR_LIMIT}
        </div>
      </div>
    </FormModal>
  );
};
