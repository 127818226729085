import React from "react";
import { useSelector } from "hooks/redux";
import { agentSelector } from "state/agentSlice";
import { Select, SelectProps } from "componentsV2/Inputs";
import { CallQueueFilter } from "../../types";
import { Role } from "api/types";

type CallTypeFilterSelectProps = Pick<SelectProps, "value" | "onChange">;

export const CallTypeFilterSelect = (props: CallTypeFilterSelectProps) => {
  const agent = useSelector(agentSelector);
  const agentRole = agent.role;

  const options = [
    {
      label: `All Calls`,
      value: CallQueueFilter.AllCalls,
      disabled: ![Role.Backend, Role.Frontend].includes(agentRole),
    },
    {
      label: `Phone Calls Only`,
      value: CallQueueFilter.PhoneCallsOnly,
      disabled: agentRole === Role.CounterOnly,
    },
    {
      label: `Counter Calls Only`,
      value: CallQueueFilter.CounterCallsOnly,
      disabled: agentRole === Role.PhoneOnly,
    },
  ];

  return (
    <Select
      aria-label="Change call type filter"
      name="call-type-filter-select"
      options={options}
      placeholder="Filter calls by:"
      selectClassNames="!pl-2 !pr-7"
      {...props}
    />
  );
};
