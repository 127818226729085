import {
  addSecondsToTimestamp,
  getDisplayDuration,
  getDisplayTime,
} from "utils/date";

/**
 * Return time range
 * @param startTime start time timestamp
 * @param duration seconds
 * @returns time range string
 */
export const getCallTimeRange = (
  startTime: string,
  duration: number
): string => {
  if (duration) {
    const endTime = addSecondsToTimestamp(startTime, duration);
    const displayDuration = getDisplayDuration(duration);

    // Duration at least 1 minute, return start time and end time with timezone and duration
    if (duration >= 60) {
      return `${getDisplayTime(startTime)} - ${getDisplayTime(endTime, {
        showTimeZone: true,
      })} (${displayDuration})`;
    }

    // Duration less than 1 minute, return start time with timezone and duration
    return `${getDisplayTime(startTime, {
      showTimeZone: true,
    })} (${displayDuration})`;
  }

  // No duration, show start time with timezone
  return getDisplayTime(startTime, {
    showTimeZone: true,
  });
};

type DialInParams = {
  phoneNumber: string;
  facilityId: number;
  tenantId: number;
  userId: number;
};
export const isDialInEnabled = (
  params: Partial<DialInParams>
): params is DialInParams => {
  return (
    typeof params.phoneNumber === "string" &&
    typeof params.facilityId === "number" &&
    typeof params.tenantId === "number" &&
    typeof params.userId === "number"
  );
};
