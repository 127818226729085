import { getInboundRecording, getOutboundRecording } from "api/phone";
import { getUniqueUsersFromTaskAssignments } from "utils/task";
import { PhoneCallRecordingData, RecordingType } from "./types";

/**
 * Fetches phone call recording data by type and normalizes the response to include required fields.
 * @param id phone call recording id
 * @param recordingType phone call recording type
 * @returns normalized phone call data
 */
export const getRecordingData = async (
  id: string | number,
  recordingType: RecordingType
): Promise<PhoneCallRecordingData> => {
  switch (recordingType) {
    case RecordingType.Outbound: {
      const res = await getOutboundRecording(id);
      const outboundRecordingData = res.data;
      return {
        presignedUrl: outboundRecordingData.presignedUrl,
        callTranscriptionId: outboundRecordingData.callTranscriptionId,
        timeCreated: outboundRecordingData.timeCreated,
        users: [outboundRecordingData.user],
        tenant: outboundRecordingData.tenant,
        facility: outboundRecordingData.facility,
      };
    }
    case RecordingType.Inbound:
    default: {
      const res = await getInboundRecording(id);
      const inboundRecordingData = res.data;
      const users = getUniqueUsersFromTaskAssignments(
        inboundRecordingData.realtimeTaskAssignments ?? []
      );
      return {
        presignedUrl: inboundRecordingData.presignedUrl,
        callTranscriptionId: inboundRecordingData.callTranscriptionId,
        timeCreated: inboundRecordingData.timeCreated,
        users,
        tenant: inboundRecordingData.realtimeTask?.tenant,
        facility: inboundRecordingData.realtimeTask?.facility,
      };
    }
  }
};
