import cn from "classnames";
import React from "react";
import { CallTranscriptSummary } from "api/types";
import { Card } from "componentsV2/Card";
import { Badge, BadgeVariant } from "componentsV2/Badges";
import { Tooltip } from "@mui/material";

const BetaBadge = () => {
  return (
    <Tooltip
      title="We're working on improved transcripts for a future release"
      placement="bottom"
    >
      <span>
        <Badge variant={BadgeVariant.GRAY} text="Beta" />
      </span>
    </Tooltip>
  );
};

type TranscriptCardProps = {
  summary?: CallTranscriptSummary["summary"];
};

export const TranscriptCard = ({ summary }: TranscriptCardProps) => {
  const hasSummary = summary && summary.length > 0;

  const heading = (
    <div className="flex items-center gap-2">
      <span>Call transcript</span>
      <BetaBadge />
    </div>
  );

  return (
    <Card heading={heading}>
      <div
        className={cn(
          "text-sm leading-6",
          hasSummary ? "text-gray-600" : "text-gray-400"
        )}
      >
        {hasSummary ? summary : "Transcript not available"}
      </div>
    </Card>
  );
};
