import cn from "classnames";
import React from "react";
import { AgentPerformanceRating, CallTranscriptSummary } from "api/types";
import { Card } from "componentsV2/Card";
import { HeadingPopover, PopoverPosition } from "componentsV2/HeadingPopover";
import { ProgressBar } from "componentsV2/ProgressBar";
import { DefinitionPopover } from "../DefinitionPopover";
import {
  AGENT_PERFORMANCE_RATING_RECORD,
  DEFINITION_ITEMS,
  DEFINITION_EXAMPLES,
  AGENT_PERFORMANCE_RATING_UNDEFINED,
} from "./constants";

type AgentCompetencyCardProps = Partial<
  Pick<
    CallTranscriptSummary,
    "agentProfessionalismRating" | "agentEmpathyRating"
  >
>;

export const AgentCompetencyCard = ({
  agentProfessionalismRating,
  agentEmpathyRating,
}: AgentCompetencyCardProps) => {
  const heading = (
    <HeadingPopover
      popover={
        <DefinitionPopover
          items={DEFINITION_ITEMS}
          examples={DEFINITION_EXAMPLES}
        />
      }
      popoverPosition={PopoverPosition.LEFT}
    >
      Agent competency
    </HeadingPopover>
  );

  const renderProgressBar = (
    categoryLabel: string,
    rating?: AgentPerformanceRating
  ) => {
    const { label, value } = rating
      ? AGENT_PERFORMANCE_RATING_RECORD[rating]
      : AGENT_PERFORMANCE_RATING_UNDEFINED;

    return (
      <div className="space-y-4">
        <div className="flex gap-2 justify-between text-gray-500">
          <div>{categoryLabel}</div>
          <div
            className={cn({ "font-medium": rating, "text-gray-400": !rating })}
          >
            {label}
          </div>
        </div>
        <ProgressBar value={value} />
      </div>
    );
  };

  return (
    <Card heading={heading}>
      <div className="space-y-6">
        {renderProgressBar("Professionalism", agentProfessionalismRating)}
        {renderProgressBar("Empathy", agentEmpathyRating)}
      </div>
    </Card>
  );
};
