import { SidebarState } from "../../types";

export const SIDEBAR_BUTTON_RECORD: Record<
  SidebarState,
  { label: string; ariaLabel: string }
> = {
  [SidebarState.HIGHLIGHTS]: {
    label: "Highlights",
    ariaLabel: "View call highlights",
  },
  [SidebarState.TRANSCRIPT]: {
    label: "Transcript",
    ariaLabel: "View call transcript",
  },
};
