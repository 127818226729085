import React from "react";
import {
  ButtonRounded,
  ButtonRoundedVariant,
} from "componentsV2/ButtonRounded";
import { useAsyncTaskFilters } from "hooks/useAsyncTaskFilters";

export const FilterReset: React.FC = () => {
  const { asyncTaskFilters, setResourceTypeFilter } = useAsyncTaskFilters();

  const noFiltersSelected = !asyncTaskFilters.resourcetype;

  const resetAsyncTaskFilters = () => setResourceTypeFilter();

  return (
    <ButtonRounded
      type="button"
      onClick={resetAsyncTaskFilters}
      role="switch"
      aria-checked={noFiltersSelected}
      variant={
        noFiltersSelected
          ? ButtonRoundedVariant.PRIMARY
          : ButtonRoundedVariant.SECONDARY
      }
    >
      All tasks
    </ButtonRounded>
  );
};
