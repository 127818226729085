import { Button } from "componentsV2/Button";
import { Modal } from "componentsV2/Modal";
import React, { useEffect, useState } from "react";
import { pusher } from "services/Pusher";
import { useIdleTimer } from "react-idle-timer";
import { updateAgent as updateAgentApi } from "api/users";
import { Status } from "api/types";
import { useDispatch, useSelector } from "hooks/redux";
import { agentSelector, updateAgent } from "state/agentSlice";
import { isUserOffline } from "utils/user";
import { useSnackbar } from "modulesV2/Snackbar";

const timeout = 3600000;
const promptBeforeIdle = 10000;

export const IdleModal: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const agent = useSelector(agentSelector);
  const { showErrorSnack } = useSnackbar();

  const onIdle = () => {
    setOpen(true);
    pusher.disconnect();
  };

  const onPrompt = () => {
    setOpen(true);
  };

  const { activate, isPrompted } = useIdleTimer({
    onIdle,
    onPrompt,
    timeout,
    promptBeforeIdle,
    eventsThrottle: 500,
    crossTab: true,
    syncTimers: 500,
  });

  const handleStillHere = async () => {
    const userIsOffline = await isUserOffline(agent.id);

    if (userIsOffline) {
      try {
        const resUpdate = await updateAgentApi({ availability: Status.Away });
        dispatch(updateAgent(resUpdate.data));
      } catch {
        showErrorSnack("Unable to update user availability.");
      }
    }
    activate();
    setOpen(false);
  };

  useEffect(() => {
    if (!isPrompted()) {
      pusher.reconnect();
    }
  }, [open]);

  return (
    <div>
      <Modal
        title="Are you still here?"
        active={open}
        handleClose={handleStillHere}
      >
        <div className="flex flex-col items-center">
          <Button type="button" onClick={handleStillHere}>
            Yes
          </Button>
        </div>
      </Modal>
    </div>
  );
};
