/**
 * Returns Date object (same or created from ISO string)
 * @param dateArg ISO string or Date object
 * @returns Date object
 */
const getDateObj = (dateArg: Date | string): Date =>
  typeof dateArg === "string" ? new Date(dateArg) : dateArg;

/**
 * Returns short date in MM/DD/YY, HH:MM AM/PM format
 * @param dateArg ISO string or Date
 * @returns short date
 */
export const formatShortDate = (dateArg: Date | string): string => {
  const date = getDateObj(dateArg);
  return date.toLocaleString("en-US", {
    timeStyle: "short",
    dateStyle: "short",
  });
};

/**
 * Returns HH:MM AM/PM string
 * @param dateArg ISO string or Date
 * @param options.showTimeZone Optional showTimeZone boolean
 * @returns display time
 */
export const getDisplayTime = (
  dateArg: Date | string,
  options?: Partial<{ showTimeZone: boolean }>
): string => {
  const date = getDateObj(dateArg);
  const formatOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
  };
  if (options?.showTimeZone) {
    formatOptions.timeZoneName = "short";
  }
  return date.toLocaleTimeString("en-us", formatOptions);
};

/**
 * Returns display date (Day, Month Date).
 * @param dateArg ISO string or Date
 * @returns display date
 */
export const getDisplayDate = (dateArg: Date | string): string => {
  const dateObj = getDateObj(dateArg);

  // "Thu Jan 01 1970" split into ["Thu", "Jan", "01", "1970"(omitted)]
  const dateString = dateObj.toDateString();
  const [day, month, date] = dateString.split(" ");

  return `${day}, ${month} ${date}`;
};

/**
 * Returns display date in "Mon D, YYYY" format (e.g., "Jan 1, 2025").
 * @param dateArg ISO string or Date
 * @returns display date
 */
export const getDisplayDateMDY = (dateArg: Date | string): string => {
  const dateObj = getDateObj(dateArg);

  // "Thu Jan 01 1970" split into ["Thu", "Jan", "01", "1970"]
  const dateString = dateObj.toDateString();
  const [, month, date, year] = dateString.split(" ");

  return `${month} ${date}, ${year}`;
};

export const TIME_DIFF_MAX_TOLERANCE = 3000;

/**
 * Checks if comparison time is within acceptable range of system time.
 * (i.e. acceptable system time could be behind comparison time by
 * AT MOST the value set in TIME_DIFF_MAX_TOLERANCE)
 * @param compare ISO string
 * @returns whether or not time diff is within acceptable tolerance
 */
export const checkIfSystemTimeAcceptable = (compare: string): boolean => {
  const compareTime = new Date(compare).getTime();
  const systemTime = new Date().getTime();
  return compareTime - systemTime <= TIME_DIFF_MAX_TOLERANCE;
};

/**
 * Format elapsed time in HH:MM:SS.
 * @param start ISO string
 * @returns formatted elapsed time
 */
export const getTimeElapsed = (start: string): string => {
  const startTime = new Date(start).getTime();
  const nowTime = new Date().getTime();

  // Handles client/server time mismatch to prevent negative values
  // (e.g. client time behind server/start time)
  const elapsedMs = Math.max(nowTime - startTime, 0);

  const elapsedSec = Math.floor(elapsedMs / 1000);
  const elapsedMin = Math.floor(elapsedSec / 60);
  const elapsedHrs = Math.floor(elapsedMin / 60);

  const hasHours = elapsedHrs > 0;
  const maxTime = elapsedHrs > 99;

  const elapsedSecDisplay = `${elapsedSec % 60}`.padStart(2, "0");
  // Only pad minutes when hours exist
  const elapsedMinDisplay = `${elapsedMin % 60}`.padStart(
    hasHours ? 2 : 1,
    "0"
  );

  // Max time display
  if (maxTime) {
    return "99:59:59";
  }
  // HH:MM:SS
  if (hasHours) {
    return `${elapsedHrs}:${elapsedMinDisplay}:${elapsedSecDisplay}`;
  }
  // MM:SS
  return `${elapsedMinDisplay}:${elapsedSecDisplay}`;
};

export const getElapsedTimeMs = (timestamp: string): number => {
  const givenTime = new Date(timestamp);
  const now = new Date();
  return now.getTime() - givenTime.getTime();
};

export const getElapsedTimeSeconds = (timestamp: string): number => {
  const elapsedTimeMs = getElapsedTimeMs(timestamp);
  return Math.floor(elapsedTimeMs / 1000);
};

export const getElapsedTimeMinutes = (timestamp: string): number => {
  const elapsedTimeMs = getElapsedTimeMs(timestamp);
  return Math.floor(elapsedTimeMs / 60000);
};

/**
 * Format Date to a customized format
 * @param date Date new to be formated
 * @returns customized timestamp
 */
export const formatDateToCustomString = (date: Date) => {
  const isoString = date.toISOString();
  const [datePart, timePart] = isoString.split("T");
  const [time, milliseconds] = timePart.split(".");
  const microseconds = milliseconds.substring(0, 3) + "000";
  return `${datePart}T${time}.${microseconds}+00:00`;
};

/**
 * Returns a display duration in Xm Ys format.
 * @param seconds Number of seconds
 * @returns formatted duration
 */
export const getDisplayDuration = (seconds: number): string => {
  const roundedSeconds = Math.round(seconds);
  const minutes = Math.floor(roundedSeconds / 60);
  const remainingSeconds = roundedSeconds % 60;
  return `${minutes}m ${remainingSeconds}s`;
};

/**
 * Adds a duration in seconds to a given timestamp.
 * @param timestamp ISO string
 * @param duration Number of seconds to add
 * @returns new timestamp with duration added
 */
export const addSecondsToTimestamp = (
  timestamp: string,
  seconds: number
): string => {
  const date = new Date(timestamp);
  date.setSeconds(date.getSeconds() + seconds);
  return date.toISOString();
};
