import React, { useRef } from "react";
import { ModalBase, ModalBaseProps } from "componentsV2/Modal";
import { Button } from "componentsV2/Button";
import { CheckIcon } from "@heroicons/react/24/solid";

type SuccessModalProps = Pick<ModalBaseProps, "active" | "handleClose">;

export const SuccessModal = ({ active, handleClose }: SuccessModalProps) => {
  const initialFocusRef = useRef<HTMLButtonElement>(
    null
  ) as React.MutableRefObject<HTMLButtonElement>;

  const title = "Feedback sent";
  const subtitle =
    "Thank you kindly! We’ll use your feedback to help improve your experience";

  return (
    <ModalBase
      initialFocusRef={initialFocusRef}
      active={active}
      handleClose={handleClose}
      title={title}
    >
      <div className="flex flex-col items-center text-center">
        <div className="rounded-full bg-green-100 p-3">
          <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
        </div>
        <div className="text-lg text-gray-900 font-medium mt-5">{title}</div>
        <div className="text-sm text-gray-500 mt-2 max-w-md">{subtitle}</div>
        <Button
          ref={initialFocusRef}
          type="button"
          onClick={handleClose}
          className="mt-6 w-full justify-center"
        >
          Close
        </Button>
      </div>
    </ModalBase>
  );
};
