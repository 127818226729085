import cn from "classnames";
import React, { forwardRef } from "react";

export enum ButtonRoundedVariant {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

export interface ButtonRoundedProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "color"> {
  variant?: ButtonRoundedVariant;
  outline?: boolean;
}

export const ButtonRounded = forwardRef<HTMLButtonElement, ButtonRoundedProps>(
  (
    {
      children,
      className,
      variant = ButtonRoundedVariant.PRIMARY,
      outline,
      ...props
    },
    buttonRef
  ) => {
    return (
      <button
        ref={buttonRef}
        className={cn(
          "px-3 py-2 rounded-full text-xs leading-4 font-medium border border-navy-900 outline-none ring-offset-1 focus-visible:ring-2 focus-visible:ring-se-blue-500 whitespace-nowrap",
          {
            "text-white bg-navy-900": variant === ButtonRoundedVariant.PRIMARY,
            "text-navy-900 bg-white":
              variant === ButtonRoundedVariant.SECONDARY,
            "ring-2 ring-se-blue-500": outline,
          },
          className
        )}
        {...props}
      >
        {children}
      </button>
    );
  }
);

ButtonRounded.displayName = "ButtonRounded";
