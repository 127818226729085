import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import cn from "classnames";
import React, { useState } from "react";
import { MoveOutTask, VmosForm } from "api/types";
import { Modal } from "componentsV2/Modal";
import { camelize } from "humps";

type MoveOutDetailsProps = {
  images: MoveOutTask["unitImages"];
  submission: MoveOutTask["formSubmission"];
  form: VmosForm | null;
};

export const MoveOutDetails = ({
  images,
  submission,
  form,
}: MoveOutDetailsProps) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  const renderImages = () => {
    return (
      <div>
        <p className="text-sm leading-5 font-medium text-gray-900">
          Unit Images
        </p>
        <ul className="flex flex-wrap gap-4 mt-4">
          {images.map((image, index) => (
            <li
              key={index}
              className="relative group rounded-md overflow-hidden"
            >
              <button
                type="button"
                className="block w-20 h-20"
                onClick={() => {
                  setShowImageModal(true);
                  setActiveImageIndex(index);
                }}
              >
                <span className="sr-only">View image {index + 1}</span>
                <img
                  src={image}
                  alt=""
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                  <EyeIcon className="w-6 h-6 text-white" />
                </div>
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderSubmission = () => {
    const submissionEntries = Object.entries(submission);

    // Store form field order values and array indexes for sorting
    const formNameOrderingMap = form
      ? submissionEntries.reduce<
          Record<string, { index: number; order: number }>
        >((acc, curr) => {
          const key = curr[0];
          const index = form.fields.findIndex(
            (field) => camelize(field.name) === key
          );

          // Key does not exist in form fields. Sort at end of list.
          // (e.g. updated form after submission)
          if (index === -1) {
            return { ...acc, [key]: { index: Infinity, order: Infinity } };
          }

          return {
            ...acc,
            [key]: { index, order: form.fields[index]?.order ?? Infinity },
          };
        }, {})
      : undefined;

    const sortedSubmissionEntries = submissionEntries.sort((a, b) => {
      if (!formNameOrderingMap) return 0;
      const { index: aIndex, order: aOrder } = formNameOrderingMap[a[0]];
      const { index: bIndex, order: bOrder } = formNameOrderingMap[b[0]];
      // If order values are equal, sort by array index
      return aOrder === bOrder ? aIndex - bIndex : aOrder - bOrder;
    });

    return (
      <div>
        <p className="text-sm leading-5 font-medium text-gray-900">
          Form Submission
        </p>
        <dl className="space-y-4 mt-4">
          {sortedSubmissionEntries.map(([key, { label, value }], index) => (
            <div
              key={key + index}
              className="text-xs leading-4 text-black space-y-2"
            >
              <dt>
                <strong>{label}</strong>
              </dt>
              <dd className="whitespace-pre-wrap">
                <p>{value || <em>No response</em>}</p>
              </dd>
            </div>
          ))}
        </dl>
      </div>
    );
  };

  const renderImageModal = () => {
    const shouldRenderNav = images.length > 1;
    const navButtonSharedClassNames =
      "absolute top-1/2 -translate-y-1/2 bg-white rounded-full p-2 text-gray-900 [&>svg]:w-6 [&>svg]:h-6";

    return (
      <Modal
        active={showImageModal}
        handleClose={() => setShowImageModal(false)}
        showCloseButton
        title="Move Out Images"
      >
        <div className={cn("relative", { "px-12": shouldRenderNav })}>
          <img
            src={images[activeImageIndex]}
            alt=""
            className="w-auto h-auto max-w-[75vw] max-h-[75vh] object-contain rounded-md"
          />
          {shouldRenderNav && (
            <>
              <button
                type="button"
                aria-label="Previous image"
                className={cn(navButtonSharedClassNames, "left-0")}
                onClick={() =>
                  setActiveImageIndex(
                    (prev) => (prev + images.length - 1) % images.length
                  )
                }
              >
                <ChevronLeftIcon aria-hidden="true" />
              </button>
              <button
                type="button"
                aria-label="Next image"
                className={cn(navButtonSharedClassNames, "right-0")}
                onClick={() =>
                  setActiveImageIndex((prev) => (prev + 1) % images.length)
                }
              >
                <ChevronRightIcon aria-hidden="true" />
              </button>
            </>
          )}
        </div>
      </Modal>
    );
  };

  return (
    <div className="space-y-4">
      {renderImages()}
      {renderSubmission()}
      {renderImageModal()}
    </div>
  );
};
