import client from "./client";
import {
  AsyncTask,
  AsyncTaskAssignment,
  AsyncTaskFilters,
  AsyncTaskResourceType,
  LeadFollowUpTask,
  MessageTask,
  MessageTaskMessage,
  MessageTaskMessageSeenListPayload,
  MissedCallFollowUpTask,
  Paginated,
  Tenant,
} from "./types";

export const getAsyncTasks = (limit: number, offset: number) =>
  client.get<Paginated<AsyncTask>>(`/v1/async-tasks`, {
    params: { limit: limit, offset: offset, is_completed: false },
  });

export const getAsyncTask = (id: number) =>
  client.get<AsyncTask>(`/v1/async-tasks/${id}`);

export const getOrganizationAsyncTasks = (
  limit: number,
  offset: number,
  filters: Partial<AsyncTaskFilters> = {}
) => {
  return client.get<Paginated<AsyncTask>>(
    `/v1/organizations/current/async-tasks`,
    {
      params: {
        limit: limit,
        offset: offset,
        is_completed: false,
        resourcetype: filters?.resourcetype,
        skill_requirements__skill__name: filters?.skillRequirements,
        facility_id: filters?.facilityIds,
      },
    }
  );
};

export const patchAsyncTask = (id: number, data: Partial<AsyncTask>) =>
  client.patch<AsyncTask>(`/v1/async-tasks/${id}`, data);

export const patchAsyncTaskV2 = async (
  task: AsyncTask,
  data: Partial<AsyncTask>
) => client.patch<AsyncTask>(task.url, data);

export const patchAsyncTaskV2Tenant = async (
  task: AsyncTask,
  tenant: Tenant
) => {
  const data = { tenantId: tenant.id };
  // Add alias tenant id field based on the task type
  switch (task.resourcetype) {
    case AsyncTaskResourceType.LeadFollowUpTask:
      (data as Partial<LeadFollowUpTask>).leadTenantId = tenant.id;
      break;
    case AsyncTaskResourceType.MessageTask:
      (data as Partial<MessageTask>).customerId = tenant.id;
      break;
    case AsyncTaskResourceType.MissedCallFollowUpTask:
      (data as Partial<MissedCallFollowUpTask>).callerId = tenant.id;
      break;
    case AsyncTaskResourceType.MoveOutTask:
    case AsyncTaskResourceType.AsyncTask:
    default:
    // No alias field for move out and generic async tasks
  }
  return client.patch<AsyncTask>(task.url, data);
};

export const postAsyncTaskAssignment = (id: number) =>
  client.post<AsyncTaskAssignment>(`/v1/async-tasks/${id}/assignments`);

export const patchAsyncTaskAssignment = (
  id: number,
  data: Partial<AsyncTaskAssignment>
) =>
  client.patch<AsyncTaskAssignment>(`/v1/async-task-assignments/${id}`, data);

/**
 * Bulk update messages with seen or not seen.
 * @param data array of MessageTaskMessage urls and seen values
 * @returns updated list of MessageTaskMessages
 */
export const patchMessageTaskMessages = (
  data: MessageTaskMessageSeenListPayload[]
) => client.patch<MessageTaskMessage[]>(`/v1/message_task_messages`, data);
