import React from "react";

import { MessageBase } from "../MessageBase";

interface EventMessageProps {
  title: string | React.ReactNode;
  content?: string | null;
  time: string;
  Icon?: React.ElementType;
  error?: boolean;
}

export const EventMessage: React.FC<EventMessageProps> = ({
  title,
  content,
  time,
  Icon,
  error,
}) => (
  <MessageBase
    title={title}
    body={content}
    Icon={Icon}
    date={time}
    error={error}
  />
);
