import {
  ArchiveBoxIcon,
  ChatBubbleLeftEllipsisIcon,
  FlagIcon,
  PhoneXMarkIcon,
  PlayCircleIcon,
} from "@heroicons/react/24/solid";

import {
  AsyncTask,
  AsyncTaskResourceType,
  LeadFollowUpTask,
  MessageTask,
  MoveOutTask,
} from "api/types";
import { InfoCardProps } from "componentsV2/InfoCard";
import { getAsyncTaskCustomer, getNewMessagesCount } from "utils/asyncTask";
import { getTenantDisplayName } from "utils/tenant";
import { formatE164ToDisplay } from "utils/phoneNumber";
import { PAGE_SIZE } from "./constants";

export const calculateNewPage = (
  taskCount: number,
  currentPage: number,
  currentPageCount: number
): number => {
  // If the total number of pages is the same
  // after removing one task or we're not
  // on the last page, stay on the same page.
  const newPageCount = Math.ceil((taskCount - 1) / PAGE_SIZE);
  if (newPageCount === currentPageCount || currentPage < currentPageCount) {
    return currentPage;
  }

  // Otherwise, decrement the page number.
  return Math.max(currentPage - 1, 1);
};

export const getVoicemailsArray = (task: AsyncTask): string[] => {
  switch (task.resourcetype) {
    case AsyncTaskResourceType.MissedCallFollowUpTask: {
      return task.missedCalls.reduce<string[]>(
        (acc, { voicemailUrl }) =>
          voicemailUrl ? [...acc, voicemailUrl] : acc,
        []
      );
    }
    case AsyncTaskResourceType.AsyncTask:
    default: {
      const voicemailUrl = task.phoneCall?.voicemailUrl;
      return voicemailUrl ? [voicemailUrl] : [];
    }
  }
};

const shouldDisplayVoicemail = (task: AsyncTask): boolean => {
  const voicemails = getVoicemailsArray(task);
  switch (task.resourcetype) {
    case AsyncTaskResourceType.MissedCallFollowUpTask:
      // Check if each voicemail count matches missedCalls count
      return voicemails.length === task.missedCalls.length;
    case AsyncTaskResourceType.AsyncTask:
    default:
      // Check if voicemail exists
      return voicemails.length > 0;
  }
};

const getCustomerIdentityText = (task: AsyncTask): string => {
  // Tenant is matched
  const customer = getAsyncTaskCustomer(task);
  if (customer) {
    return getTenantDisplayName(customer);
  }

  // Fallback for MessageTask, use customerPhoneNumber from conversation
  if (task.resourcetype === AsyncTaskResourceType.MessageTask) {
    return formatE164ToDisplay(task.conversation.customerPhoneNumber);
  }

  // Phone call has fromPhoneNumber
  if (task.phoneCall?.fromPhoneNumber) {
    return formatE164ToDisplay(task.phoneCall.fromPhoneNumber);
  }

  return "";
};

const getLeadFollowUpTitle = (task: LeadFollowUpTask): string => {
  const { additionalNotes } = task;
  if (additionalNotes) {
    const formattedNotes =
      additionalNotes[0].toUpperCase() + additionalNotes.slice(1);
    return formattedNotes;
  }

  return "Followup required";
};

const getMessageTitle = (task: MessageTask): string => {
  const customerIdentityText = getCustomerIdentityText(task);
  const customerFromText = customerIdentityText
    ? ` from ${customerIdentityText}`
    : "";

  const newMessagesCount = getNewMessagesCount(task);

  if (newMessagesCount === 1) {
    return `New message${customerFromText}`;
  }

  if (newMessagesCount > 1) {
    return `${newMessagesCount} new messages${customerFromText}`;
  }

  return `Messages${customerFromText}`;
};

const getMoveOutTitle = (task: MoveOutTask): string => {
  const customerIdentityText = getCustomerIdentityText(task);
  const customerFromText = customerIdentityText
    ? ` from ${customerIdentityText}`
    : "";
  const unitNameText = task.unitName ? ` for Unit ${task.unitName}` : "";

  return `Move out request${customerFromText}${unitNameText}`;
};

const getMissedCallFollowupTitle = (task: AsyncTask): string => {
  const customerIdentityText = getCustomerIdentityText(task);
  const customerFromTextCall = customerIdentityText
    ? ` from ${customerIdentityText}`
    : "";
  const customerFromTextVoicemail = customerIdentityText
    ? ` left by ${customerIdentityText}`
    : "";
  const displayVoicemail = shouldDisplayVoicemail(task);

  switch (task.resourcetype) {
    case AsyncTaskResourceType.MissedCallFollowUpTask: {
      const missedCallsCount = task.missedCalls.length;

      // If each missed call has a voicemail, title should refer to voicemails
      if (displayVoicemail) {
        const typeText =
          missedCallsCount > 1 ? `${missedCallsCount} voicemails` : "Voicemail";
        return `${typeText}${customerFromTextVoicemail}`;
      }

      // Otherwise (e.g. 3 missed calls but only 1 voicemail), refer to missed calls
      const typeText =
        missedCallsCount > 1
          ? `${missedCallsCount} missed calls`
          : "Missed call";
      return `${typeText}${customerFromTextCall}`;
    }
    case AsyncTaskResourceType.AsyncTask:
    default: {
      // For AsyncTask (V1)
      if (displayVoicemail) {
        return `Voicemail${customerFromTextVoicemail}`;
      }

      return `Missed call${customerFromTextCall}`;
    }
  }
};

export const getInfoCardTitle = (task: AsyncTask): string => {
  switch (task.resourcetype) {
    case AsyncTaskResourceType.LeadFollowUpTask:
      return getLeadFollowUpTitle(task);
    case AsyncTaskResourceType.MessageTask:
      return getMessageTitle(task);
    case AsyncTaskResourceType.MoveOutTask:
      return getMoveOutTitle(task);
    case AsyncTaskResourceType.MissedCallFollowUpTask:
    case AsyncTaskResourceType.AsyncTask:
    default: {
      return getMissedCallFollowupTitle(task);
    }
  }
};

type InfoCardIconProps = Pick<InfoCardProps, "Icon" | "iconClassName">;

const getLeadFollowUpIcon = (): InfoCardIconProps => ({
  Icon: FlagIcon,
  iconClassName: "text-se-blue-500",
});

const getMessageIcon = (): InfoCardIconProps => ({
  Icon: ChatBubbleLeftEllipsisIcon,
  iconClassName: "text-green-700",
});

const getMoveOutIcon = (): InfoCardIconProps => ({
  Icon: ArchiveBoxIcon,
  iconClassName: "text-orange-500",
});

const getMissedCallFollowUpIcon = (task: AsyncTask): InfoCardIconProps => {
  const displayVoicemail = shouldDisplayVoicemail(task);
  return {
    Icon: displayVoicemail ? PlayCircleIcon : PhoneXMarkIcon,
    iconClassName: "text-red-900",
  };
};

export const getInfoCardIcon = (task: AsyncTask): InfoCardIconProps => {
  switch (task.resourcetype) {
    case AsyncTaskResourceType.LeadFollowUpTask: {
      return getLeadFollowUpIcon();
    }
    case AsyncTaskResourceType.MessageTask: {
      return getMessageIcon();
    }
    case AsyncTaskResourceType.MoveOutTask: {
      return getMoveOutIcon();
    }
    case AsyncTaskResourceType.MissedCallFollowUpTask:
    case AsyncTaskResourceType.AsyncTask:
    default: {
      return getMissedCallFollowUpIcon(task);
    }
  }
};
