import { BlockquoteColor } from "componentsV2/Blockquote";
import { BadgeVariant } from "componentsV2/Badges/Badge";
import { SentimentValue } from "./types";

export const DEFINITION_ITEMS = [
  {
    term: "Beginning sentiment",
    definition: "The caller's emotion at the start of the call.",
  },
  {
    term: "Ending sentiment",
    definition: "The caller's emotion at the end of the call.",
  },
];

export const DEFINITION_EXAMPLES = {
  label: "Possible scores",
  value:
    "Angry, Frustrated, Anxious, Confused, Neutral, Hopeful, Satisfied, Relieved",
};

export const POSITIVE_SENTIMENT_VALUES = ["Hopeful", "Satisfied", "Relieved"];

export const NEUTRAL_SENTIMENT_VALUES = ["Neutral"];

export const NEGATIVE_SENTIMENT_VALUES = [
  "Angry",
  "Frustrated",
  "Anxious",
  "Confused",
];

export const VALID_SENTIMENT_VALUES = [
  ...POSITIVE_SENTIMENT_VALUES,
  ...NEUTRAL_SENTIMENT_VALUES,
  ...NEGATIVE_SENTIMENT_VALUES,
];

export const SENTIMENT_VALUE_RECORD: Record<
  SentimentValue,
  { blockquoteColor: BlockquoteColor; badgeVariant: BadgeVariant }
> = {
  [SentimentValue.POSITIVE]: {
    blockquoteColor: BlockquoteColor.GREEN,
    badgeVariant: BadgeVariant.GREEN,
  },
  [SentimentValue.NEGATIVE]: {
    blockquoteColor: BlockquoteColor.RED,
    badgeVariant: BadgeVariant.RED,
  },
  [SentimentValue.NEUTRAL]: {
    blockquoteColor: BlockquoteColor.BLUE,
    badgeVariant: BadgeVariant.BLUE,
  },
};
