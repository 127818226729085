import React from "react";
import {
  ButtonRounded,
  ButtonRoundedVariant,
} from "componentsV2/ButtonRounded";
import { SidebarState } from "../../types";
import { SIDEBAR_BUTTON_RECORD } from "./constants";

type SidebarButtonsProps = {
  state: SidebarState;
  setState: React.Dispatch<React.SetStateAction<SidebarState>>;
};

export const SidebarButtons = ({ state, setState }: SidebarButtonsProps) => {
  return (
    <div className="flex gap-3 flex-wrap">
      {Object.values(SidebarState).map((sidebarState, index) => {
        const isActive = sidebarState === state;
        const variant = isActive
          ? ButtonRoundedVariant.PRIMARY
          : ButtonRoundedVariant.SECONDARY;
        const { label, ariaLabel } = SIDEBAR_BUTTON_RECORD[sidebarState];
        return (
          <ButtonRounded
            key={index}
            variant={variant}
            onClick={() => setState(sidebarState)}
            aria-label={ariaLabel}
            aria-pressed={isActive}
          >
            {label}
          </ButtonRounded>
        );
      })}
    </div>
  );
};
