import cn from "classnames";
import React from "react";

export enum BlockquoteColor {
  GRAY = "GRAY",
  GREEN = "GREEN",
  YELLOW = "YELLOW",
  RED = "RED",
  BLUE = "BLUE",
  INDIGO = "INDIGO",
}

interface BlockquoteProps {
  children?: React.ReactNode;
  color?: BlockquoteColor;
}

const colorClasses: Record<BlockquoteColor, string> = {
  [BlockquoteColor.GRAY]: "border-gray-200 text-gray-700",
  [BlockquoteColor.GREEN]: "border-green-200 text-green-700",
  [BlockquoteColor.YELLOW]: "border-yellow-200 text-yellow-700",
  [BlockquoteColor.RED]: "border-red-200 text-red-700",
  [BlockquoteColor.BLUE]: "border-blue-200 text-blue-700",
  [BlockquoteColor.INDIGO]: "border-indigo-200 text-indigo-700",
};

export const Blockquote: React.FC<BlockquoteProps> = ({
  children,
  color = BlockquoteColor.GRAY,
}) => {
  return (
    <div className={cn("pl-4 py-1 border-l-2 text-xs", colorClasses[color])}>
      {children}
    </div>
  );
};
