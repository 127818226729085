import { createCallTranscriptSummaryFeedback } from "api/phone";
import { FeedbackSubject, FeedbackType } from "api/types";
import { FeedbackCategory, FeedbackInputName } from "./types";

export const getRecordFromFormData = (data: FormData): Record<string, string> =>
  Object.fromEntries(
    Array.from(data.entries()).map(([key, value]) => [key, String(value)])
  );

const VALID_FEEDBACK_TYPES: string[] = Object.values(FeedbackType);
const isValidFeedbackType = (
  feedbackType?: string
): feedbackType is FeedbackType =>
  Boolean(feedbackType && VALID_FEEDBACK_TYPES.includes(feedbackType));

type SubmitFeedbackParams = {
  id: string | number;
  category: FeedbackCategory;
  data: Record<string, string>;
};

export const submitFeedback = ({
  id,
  category,
  data,
}: SubmitFeedbackParams) => {
  switch (category) {
    case FeedbackCategory.Summary: {
      const {
        [FeedbackInputName.SummaryRating]: feedbackType,
        [FeedbackInputName.SummaryDetails]: comment,
      } = data;

      if (!isValidFeedbackType(feedbackType)) {
        throw new Error("Invalid summary feedback type");
      }

      return createCallTranscriptSummaryFeedback(id, {
        feedbackType,
        feedbackSubject: FeedbackSubject.SUMMARY,
        comment,
      });
    }
    case FeedbackCategory.Scoring: {
      const {
        [FeedbackInputName.ScoringSentimentRating]: sentimentFeedbackType,
        [FeedbackInputName.ScoringCompetencyRating]: competencyFeedbackType,
        [FeedbackInputName.ScoringDetails]: comment,
      } = data;

      // NOTE: use same comment for both subjects
      const createFeedbackPromises: ReturnType<
        typeof createCallTranscriptSummaryFeedback
      >[] = [];

      if (sentimentFeedbackType) {
        if (!isValidFeedbackType(sentimentFeedbackType)) {
          throw new Error("Invalid sentiment feedback type");
        }

        createFeedbackPromises.push(
          createCallTranscriptSummaryFeedback(id, {
            feedbackType: sentimentFeedbackType,
            feedbackSubject: FeedbackSubject.CALL_SENTIMENT,
            comment,
          })
        );
      }

      if (competencyFeedbackType) {
        if (!isValidFeedbackType(competencyFeedbackType)) {
          throw new Error("Invalid competency feedback type");
        }

        createFeedbackPromises.push(
          createCallTranscriptSummaryFeedback(id, {
            feedbackType: competencyFeedbackType,
            feedbackSubject: FeedbackSubject.AGENT_COMPETENCY,
            comment,
          })
        );
      }

      // Both feedbackTypes missing, error since at least one is required
      if (createFeedbackPromises.length === 0) {
        throw new Error("Feedback type is required");
      }

      return Promise.all(createFeedbackPromises);
    }
    default:
      throw new Error("Invalid feedback category");
  }
};
