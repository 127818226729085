import React from "react";
import cn from "classnames";
import { Outlet } from "react-router-dom";

import { LoadingScreen } from "components";
import { useDashboard } from "contexts/DashboardContext";
import { useLayout } from "contexts/LayoutContext";
import { Path } from "routes/types";

import { AwayBanner } from "./components/AwayBanner";
import { CallQueueBanner } from "./components/CallQueueBanner";
import { Sidebar } from "./components/Sidebar";
import { CallQueueBar } from "modulesV2/CallQueue/CallQueueBar";
import { IdleModal } from "modulesV2/AutoLogout";

export const Dashboard = () => {
  const { loading, activeCalls, asyncTasksCount } = useDashboard();
  const { isAway, sidebarExpanded } = useLayout();

  if (loading) {
    return <LoadingScreen message="Loading StoreEase VMOS..." />;
  }

  const notifications = {
    [Path.Calls]: activeCalls.length ? `${activeCalls.length}` : undefined,
    [Path.Tasks]: asyncTasksCount > 0 ? `${asyncTasksCount}` : undefined,
  };

  return (
    <div>
      {isAway && (
        <AwayBanner text="You are not receiving any calls until you return your status to available." />
      )}
      <Sidebar notifications={notifications} />

      <div
        className={cn({
          "pl-16": !sidebarExpanded,
          "pl-64": sidebarExpanded,
        })}
      >
        <CallQueueBanner
          className={cn({
            "pr-16": !sidebarExpanded,
            "pr-64": sidebarExpanded,
          })}
        >
          <CallQueueBar />
        </CallQueueBanner>
        <IdleModal />

        <main className="px-2 py-3 mb-20">
          <Outlet />
        </main>
      </div>
    </div>
  );
};
