import React, { useState } from "react";
import { useSnackbar } from "modulesV2/Snackbar";
import { DetailsModal } from "./DetailsModal";
import { RatingModal } from "./RatingModal";
import { SuccessModal } from "./SuccessModal";
import { FEEDBACK_CATEGORY_RECORD } from "./constants";
import { FeedbackModalState, FeedbackCategory } from "./types";
import { getRecordFromFormData, submitFeedback } from "./utils";

type FeedbackPromptProps = {
  callTranscriptSummaryId?: number;
  category: FeedbackCategory;
};

export const FeedbackPrompt = ({
  callTranscriptSummaryId,
  category,
}: FeedbackPromptProps) => {
  const [modalState, setModalState] = useState<FeedbackModalState>(
    FeedbackModalState.Inactive
  );
  const [ratingsValue, setRatingsValue] = useState<Record<string, string>>({});
  const [detailsValue, setDetailsValue] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showErrorSnack } = useSnackbar();

  const { promptLabel, formTitle, radioGroups, detailsInputName } =
    FEEDBACK_CATEGORY_RECORD[category];

  const handleClose = () => {
    setRatingsValue({});
    setDetailsValue("");
    setModalState(FeedbackModalState.Inactive);
  };

  // Store submitted ratings data in local state
  const handleSubmitRatings = (data: FormData) => {
    const ratings = getRecordFromFormData(data);
    setRatingsValue(ratings);
    setModalState(FeedbackModalState.Details);
  };

  // Use submitted details and stored ratings data to create feedback
  const handleSubmitDetails = async (data: FormData) => {
    if (isSubmitting || !callTranscriptSummaryId) {
      return;
    }

    try {
      setIsSubmitting(true);
      const details = getRecordFromFormData(data);
      await submitFeedback({
        id: callTranscriptSummaryId,
        data: { ...ratingsValue, ...details },
        category,
      });
      setModalState(FeedbackModalState.Success);
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : "Unable to submit feedback";
      showErrorSnack(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!callTranscriptSummaryId) {
    return null;
  }

  return (
    <>
      <RatingModal
        active={modalState === FeedbackModalState.Rating}
        handleClose={handleClose}
        title={formTitle}
        handleSubmit={handleSubmitRatings}
        radioGroups={radioGroups}
        defaultCheckedValues={ratingsValue}
      />
      <DetailsModal
        active={modalState === FeedbackModalState.Details}
        handleClose={handleClose}
        title={formTitle}
        value={detailsValue}
        handleChange={(e) => setDetailsValue(e.target.value)}
        handleBack={() => setModalState(FeedbackModalState.Rating)}
        handleSubmit={handleSubmitDetails}
        detailsInputName={detailsInputName}
        isSubmitting={isSubmitting}
      />
      <SuccessModal
        active={modalState === FeedbackModalState.Success}
        handleClose={handleClose}
      />
      <button
        type="button"
        className="text-xs text-gray-400 block mx-auto"
        onClick={() => setModalState(FeedbackModalState.Rating)}
      >
        {promptLabel}
      </button>
    </>
  );
};
