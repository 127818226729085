import { PhoneIcon } from "@heroicons/react/24/solid";
import { AxiosError } from "axios";
import { Howl } from "howler";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import createPersistedState from "use-persisted-state";
import { CallQueueItem } from "api/types";
import { Button, ButtonColor } from "componentsV2/Button";
import { useClarity } from "hooks/clarity";
import { useDispatch, useSelector, useStore } from "hooks/redux";
import { useSnackbar } from "modulesV2/Snackbar";
import { Path } from "routes/types";
import { agentSelector } from "state/agentSlice";
import { callQueueDataSelector } from "state/callQueueSlice";
import { isDialedInSelector, upsertTaskAssignment } from "state/tasksSlice";
import { acceptCall, fetchAllTasks, fetchCallQueue } from "services/tasks";
import { CallTypeFilterSelect } from "./components/CallTypeFilterSelect";
import { NextCallQueueItem } from "./components/NextCallQueueItem";
import { CallQueueFilter } from "./types";
import {
  checkIfCallQueueItemShouldNotifyAgent,
  createTaskAssignmentForCallQueueItem,
  getCallQueueWaitingText,
  getInitialCallQueueFilter,
  getItemsFilteredByRole,
  getItemsFilteredByTypeFilter,
  getNextCallQueueItem,
  getNewItemNotificationText,
} from "./utils";

const useCallQueueFilterState =
  createPersistedState<CallQueueFilter>("call_queue_filter");

export const CallQueueBar: React.FC = () => {
  const { claritySetAgentAcceptTask } = useClarity();
  const navigate = useNavigate();
  const snacks = useSnackbar();
  const dispatch = useDispatch();
  const agent = useSelector(agentSelector);
  const callQueueData = useSelector(callQueueDataSelector);
  const isDialedIn = useSelector(isDialedInSelector);
  const store = useStore();
  const shouldMuteTaskAlert = agent.isSecondaryAlertMuted && isDialedIn;

  const initialFilterValue = getInitialCallQueueFilter(agent.role);
  const [filterValue, setFilterValue] =
    useCallQueueFilterState(initialFilterValue);
  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    const selectedValue = e.target.value as CallQueueFilter;
    setFilterValue(selectedValue);
  };

  // All items in queue
  const callQueueItems = useMemo(
    () => callQueueData?.queue ?? [],
    [callQueueData?.queueSequenceId]
  );
  // Items filtered by user role
  const itemsFilteredByRole = getItemsFilteredByRole(
    callQueueItems,
    agent.role
  );
  // Items filtered by user filter setting
  const itemsFilteredByFilterType = getItemsFilteredByTypeFilter(
    callQueueItems,
    filterValue
  );

  const [callQueueHasInitialized, setCallQueueHasInitialized] = useState(false);
  const [processedItems, setProcessedItems] = useState<CallQueueItem[]>([]);
  const [nextCallQueueItem, setNextCallQueueItem] = useState<
    CallQueueItem | undefined
  >();

  const callQueueSound = new Howl({
    src: ["/assets/callqueue.wav"],
  });

  const processUpdatedItems = (updatedItems: CallQueueItem[]) => {
    if (callQueueHasInitialized) {
      const newItems = updatedItems.filter(
        (newItem) =>
          !processedItems.some((oldItem) => oldItem.url === newItem.url)
      );

      const itemsToNotify = newItems.filter((item) =>
        checkIfCallQueueItemShouldNotifyAgent(item, agent.role)
      );

      if (itemsToNotify.length > 0) {
        itemsToNotify.forEach((item) => {
          snacks.showInfoSnack(getNewItemNotificationText(item));
        });
        if (!shouldMuteTaskAlert) {
          callQueueSound.play();
        }
      }
    }

    setProcessedItems(updatedItems);
  };

  const handleClaimCall = async () => {
    if (!nextCallQueueItem) {
      snacks.showErrorSnack("An error occurred while claiming the call.");
      return;
    }

    try {
      const res = await createTaskAssignmentForCallQueueItem(
        nextCallQueueItem,
        agent
      );
      if (res.data) {
        navigate(Path.Calls);
        claritySetAgentAcceptTask(agent, res.data);
        dispatch(upsertTaskAssignment(res.data));
        await acceptCall(res.data, store, snacks);
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err?.response?.status === 409) {
        snacks.showErrorSnack("This call is no longer available");
        try {
          await Promise.all([
            dispatch(fetchCallQueue),
            dispatch(fetchAllTasks),
          ]);
        } catch {
          snacks.showErrorSnack(
            "An error occurred while reloading call queue."
          );
        }
      } else {
        snacks.showErrorSnack("An error occurred while claiming the call.");
      }
    }
  };

  useEffect(
    function handleCallQueueItemsUpdated() {
      processUpdatedItems(callQueueItems);
      setCallQueueHasInitialized(true);
    },
    [callQueueItems]
  );

  useEffect(
    function handleItemsFilteredByFilterTypeUpdated() {
      const updateNextCallQueueItem = (items: CallQueueItem[]) => {
        const nextItem = getNextCallQueueItem(items);
        setNextCallQueueItem(nextItem);
      };

      updateNextCallQueueItem(itemsFilteredByFilterType);
      const updateNextCallQueueItemInterval = setInterval(() => {
        updateNextCallQueueItem(itemsFilteredByFilterType);
      }, 15 * 1000);

      return () => {
        clearInterval(updateNextCallQueueItemInterval);
      };
    },
    [itemsFilteredByFilterType]
  );

  // Hide Call Queue when there are no items applicable to agent role
  if (!itemsFilteredByRole.length) return null;

  return (
    <div className="flex text-sm justify-between bg-white border-gray-300 border-t items-center w-full p-4">
      <div className="flex-1">
        {nextCallQueueItem && <NextCallQueueItem item={nextCallQueueItem} />}
      </div>
      <div className="flex flex-row items-center gap-3 text-gray-500">
        <div>{getCallQueueWaitingText(itemsFilteredByRole, filterValue)}</div>
        <CallTypeFilterSelect value={filterValue} onChange={handleChange} />
        <Button
          type="button"
          color={ButtonColor.GREEN}
          Icon={PhoneIcon}
          onClick={handleClaimCall}
          disabled={!nextCallQueueItem}
        >
          Claim Call
        </Button>
      </div>
    </div>
  );
};
