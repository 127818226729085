import { User, Status, UserEventType } from "api/types";
import { getAgentLatestEvent } from "api/users";

export const userStatus = (user: User) => {
  if (!user.taskChannel?.isPopulated) {
    return Status.Offline;
  }
  return user.availability;
};

export const displayName = (user: User) => {
  if (user.firstName.length === 0 && user.lastName.length === 0) {
    return user.username;
  }
  return `${user.firstName} ${user.lastName}`.trim();
};

// Similar to displayName but returns email as a fallback instead
export const getUserDisplayName = (user: User) => {
  if (user.firstName.length === 0 && user.lastName.length === 0) {
    return user.email;
  }

  return `${user.firstName} ${user.lastName}`.trim();
};

export const getFirstName = (user: User) =>
  (user.firstName.length > 0 ? user.firstName : user.username).trim();

/**
 * Check if the user has been offline for over the default 15 minutes duration.
 * @param userId id of the user to check
 * @param durationMs duration in milliseconds, default is 15 minutes
 * @returns true if the user has been offline for over the duration, false otherwise
 */
export const isUserOffline = async (
  userId: number,
  durationMs: number = 900000
): Promise<boolean> => {
  const userEventRes = await getAgentLatestEvent(userId);
  return (
    userEventRes.data.type === UserEventType.OFFLINE &&
    new Date().getTime() - new Date(userEventRes.data.timeCreated).getTime() >
      durationMs
  );
};
