export enum ButtonMenuActionType {
  Button = "Button",
  Link = "Link",
}

export type ButtonMenuAction =
  | {
      type: ButtonMenuActionType.Button;
      label: string;
      onClick: () => void;
      disabled?: false;
    }
  | {
      type: ButtonMenuActionType.Button;
      label: string;
      disabled: true;
      disabledMessage?: string;
    }
  | { type: ButtonMenuActionType.Link; label: string; href: string };

export enum ButtonMenuAlignment {
  Right = "Right",
  Left = "Left",
}
