import { SparklesIcon } from "@heroicons/react/24/outline";
import React from "react";
import { CallTranscriptSummary } from "api/types";
import { AlertMessage } from "componentsV2/AlertMessage";
import { Card } from "componentsV2/Card";
import { HeadingPopover, PopoverPosition } from "componentsV2/HeadingPopover";
import { DefinitionPopover } from "../DefinitionPopover";
import { NOTABLE_CALLS_ALERT_MESSAGE_PROPS } from "./constants";

export type SummaryCardProps = Partial<
  Pick<
    CallTranscriptSummary,
    "summary" | "notableCalls" | "notableCallReasoning"
  >
>;

export const SummaryCard = ({
  summary,
  notableCalls,
  notableCallReasoning,
}: SummaryCardProps) => {
  const renderSummaryText = () => {
    if (!summary) {
      return <div className="text-xs text-gray-400">Summary not available</div>;
    }

    return <div>{summary}</div>;
  };

  const renderNotableCalls = () => {
    if (!(notableCalls && notableCallReasoning)) return null;
    const alertMessageProps = NOTABLE_CALLS_ALERT_MESSAGE_PROPS[notableCalls];
    if (!alertMessageProps) return null;

    const { Icon, color, title: titleText, definition } = alertMessageProps;

    const title =
      titleText && definition ? (
        <HeadingPopover
          popover={
            <DefinitionPopover items={[{ term: titleText, definition }]} />
          }
          popoverPosition={PopoverPosition.BOTTOM}
        >
          {titleText}
        </HeadingPopover>
      ) : null;

    return (
      <div className="mt-4">
        <AlertMessage
          color={color}
          Icon={Icon}
          title={title}
          message={notableCallReasoning}
        />
      </div>
    );
  };

  return (
    <Card
      heading={
        <div className="flex items-center gap-2">
          <SparklesIcon className="h-5 w-5 text-gray-500" />
          <span>Summary</span>
        </div>
      }
    >
      {renderSummaryText()}
      {renderNotableCalls()}
    </Card>
  );
};
