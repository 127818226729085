import React from "react";

type DefinitionPopoverProps = {
  items: { term: string; definition: string }[];
  examples?: { label: string; value: string };
};

export const DefinitionPopover = ({
  items,
  examples,
}: DefinitionPopoverProps) => {
  return (
    <div className="w-[344px]">
      <dl className="text-sm space-y-6">
        {items.map(({ term, definition }, index) => (
          <div key={index} className="space-y-2">
            <dt className="font-medium text-gray-900">{term}</dt>
            <dd className="text-gray-500">{definition}</dd>
          </div>
        ))}
      </dl>
      {examples && (
        <div className="mt-5 pt-5 border-t border-gray-200 space-y-1 text-xs">
          <div className="font-medium text-gray-900">{examples.label}</div>
          <div className="text-gray-500 leading-5">{examples.value}</div>
        </div>
      )}
    </div>
  );
};
