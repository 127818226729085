import { AsyncTaskResourceType } from "api/types";
import { Filter, FilterType } from "./components/Filters/types";

export const PAGE_SIZE = 10;

export const LEAD_FOLLOW_UP_FILTER: Filter = {
  type: FilterType.Toggle,
  label: "Lead follow ups",
  resourcetype: AsyncTaskResourceType.LeadFollowUpTask,
};

export const MESSAGE_TASK_FILTER: Filter = {
  type: FilterType.Toggle,
  label: "Messages",
  resourcetype: AsyncTaskResourceType.MessageTask,
};

export const MOVE_OUT_TASK_FILTER: Filter = {
  type: FilterType.Toggle,
  label: "Move outs",
  resourcetype: AsyncTaskResourceType.MoveOutTask,
};

export const MISSED_CALL_FOLLOW_UP_FILTER: Filter = {
  type: FilterType.Group,
  label: "Missed calls",
  resourcetype: AsyncTaskResourceType.MissedCallFollowUpTask,
  skillRequirements: [
    { label: "Sales", value: "Sales" },
    { label: "Payments", value: "Service - Payments" },
    { label: "Unit access", value: "Service - Maintenance/Access Issues" },
    { label: "Customer complaints", value: "Service - Customer Complaints" },
    { label: "Adjacent services", value: "Service - Adjacent Services" },
    { label: "All other inquiries", value: "null" },
  ],
};

export const ASYNC_TASK_FILTERS: Filter[] = [
  LEAD_FOLLOW_UP_FILTER,
  MESSAGE_TASK_FILTER,
  MOVE_OUT_TASK_FILTER,
  MISSED_CALL_FOLLOW_UP_FILTER,
];
