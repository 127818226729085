import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCallTranscript } from "api/phone";
import { CallTranscription } from "api/types";
import { AlertMessage, AlertMessageColor } from "componentsV2/AlertMessage";
import { useSnackbar } from "modulesV2/Snackbar";
import { AgentCompetencyCard } from "./components/AgentCompetencyCard";
import { CallDetailsCard } from "./components/CallDetailsCard";
import { CallHeader } from "./components/CallHeader";
import { CallSentimentCard } from "./components/CallSentimentCard";
import { FeedbackPrompt, FeedbackCategory } from "./components/FeedbackPrompt";
import { MediaPlayer, MediaType } from "./components/MediaPlayer";
import { SidebarButtons } from "./components/SidebarButtons";
import { SummaryCard } from "./components/SummaryCard";
import { TranscriptCard } from "./components/TranscriptCard";
import styles from "./CallSummary.module.css";
import {
  RecordingType,
  SidebarState,
  OnMediaCanPlayThroughEventHandler,
  PhoneCallRecordingData,
} from "./types";
import { getRecordingData } from "./utils";

interface CallSummaryProps {
  recordingType?: RecordingType;
}

export const CallSummary: React.FC<CallSummaryProps> = ({
  recordingType = RecordingType.Inbound,
}) => {
  const params = useParams();

  const { showErrorSnack } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState<PhoneCallRecordingData>();
  const [recordingError, setRecordingError] = useState("");
  const [callTranscript, setCallTranscript] = useState<CallTranscription>();
  const [callTranscriptError, setCallTranscriptError] = useState("");
  const [sidebarState, setSidebarState] = useState<SidebarState>(
    SidebarState.HIGHLIGHTS
  );
  const [mediaDuration, setMediaDuration] = useState(0);
  const onCanPlayThrough: OnMediaCanPlayThroughEventHandler = (e) => {
    const duration = e.currentTarget.duration;
    const isValid = !isNaN(duration) && duration > 0;
    setMediaDuration(isValid ? duration : 0);
  };

  useEffect(() => {
    const fetchRecordingData = async (recordingId: string) => {
      try {
        setLoading(true);
        setRecordingError("");
        const data = await getRecordingData(recordingId, recordingType);
        setRecording(data);
      } catch (e) {
        showErrorSnack("Unable to retrieve recording");
        setRecordingError(e instanceof Error ? e.message : JSON.stringify(e));
      } finally {
        setLoading(false);
      }
    };

    if (params.id) {
      fetchRecordingData(params.id);
    }
  }, [params.id, recordingType]);

  useEffect(() => {
    const fetchCallTranscript = async (id: number) => {
      try {
        setLoading(true);
        setCallTranscriptError("");
        const res = await getCallTranscript(id);
        setCallTranscript(res.data);
      } catch (e) {
        showErrorSnack("Unable to retrieve call transcript");
        setCallTranscriptError(
          e instanceof Error ? e.message : JSON.stringify(e)
        );
      } finally {
        setLoading(false);
      }
    };

    if (recording?.callTranscriptionId) {
      fetchCallTranscript(recording.callTranscriptionId);
    }
  }, [recording?.callTranscriptionId]);

  const renderErrors = () => {
    if (!(recordingError || callTranscriptError)) {
      return null;
    }

    return (
      <div className="mb-2 space-y-2">
        {recordingError && (
          <AlertMessage
            color={AlertMessageColor.RED}
            Icon={ExclamationTriangleIcon}
            title="Unable to retrieve recording"
            message={recordingError}
          />
        )}
        {callTranscriptError && (
          <AlertMessage
            color={AlertMessageColor.RED}
            Icon={ExclamationTriangleIcon}
            title="Unable to retrieve call transcript"
            message={callTranscriptError}
          />
        )}
      </div>
    );
  };

  const renderMedia = () => {
    if (!recording?.presignedUrl) {
      return <MediaPlayer type={MediaType.Unavailable} loading={loading} />;
    }

    return (
      <MediaPlayer
        src={recording.presignedUrl}
        type={MediaType.Audio}
        onCanPlayThrough={onCanPlayThrough}
      />
    );
  };

  const renderSummary = () => {
    const { summary, notableCalls, notableCallReasoning } =
      callTranscript?.transcriptSummary || {};

    return (
      <SummaryCard
        summary={summary}
        notableCalls={notableCalls}
        notableCallReasoning={notableCallReasoning}
      />
    );
  };

  const renderSidebarContent = () => {
    switch (sidebarState) {
      case SidebarState.TRANSCRIPT:
        return <TranscriptCard summary={callTranscript?.text} />;
      case SidebarState.HIGHLIGHTS:
      default: {
        const {
          startingSentiment,
          startingSentimentAnalysis,
          endingSentiment,
          endingSentimentAnalysis,
          agentProfessionalismRating,
          agentEmpathyRating,
        } = callTranscript?.transcriptSummary || {};
        return (
          <>
            <CallDetailsCard
              recording={recording}
              recordingId={params.id}
              callDuration={mediaDuration}
              recordingType={recordingType}
            />
            <CallSentimentCard
              startingSentiment={startingSentiment}
              startingSentimentAnalysis={startingSentimentAnalysis}
              endingSentiment={endingSentiment}
              endingSentimentAnalysis={endingSentimentAnalysis}
            />
            <AgentCompetencyCard
              agentProfessionalismRating={agentProfessionalismRating}
              agentEmpathyRating={agentEmpathyRating}
            />
            <FeedbackPrompt
              callTranscriptSummaryId={callTranscript?.transcriptSummary?.id}
              category={FeedbackCategory.Scoring}
            />
          </>
        );
      }
    }
  };

  return (
    <div className="px-3 py-2">
      <div className={cn("max-w-7xl", styles.grid)}>
        <div className={cn(styles.main, "space-y-6")}>
          {renderErrors()}
          <CallHeader recording={recording} recordingType={recordingType} />
          {renderMedia()}
          {renderSummary()}
          <FeedbackPrompt
            callTranscriptSummaryId={callTranscript?.transcriptSummary?.id}
            category={FeedbackCategory.Summary}
          />
        </div>
        <div className={cn(styles.sidebar, "space-y-6")}>
          <SidebarButtons state={sidebarState} setState={setSidebarState} />
          {renderSidebarContent()}
        </div>
      </div>
    </div>
  );
};
