import { AgentPerformanceRating } from "api/types";
import { ProgressBarProps } from "componentsV2/ProgressBar";

export const AGENT_PERFORMANCE_RATING_RECORD: Record<
  AgentPerformanceRating,
  { label: string; value: ProgressBarProps["value"] }
> = {
  [AgentPerformanceRating.POSITIVE]: {
    label: "Positive",
    value: 100,
  },
  [AgentPerformanceRating.NEUTRAL]: {
    label: "Neutral",
    value: 50,
  },
  [AgentPerformanceRating.NEGATIVE]: {
    label: "Negative",
    value: 25,
  },
};

export const AGENT_PERFORMANCE_RATING_UNDEFINED = {
  label: "Not available",
  value: 0,
};

export const DEFINITION_ITEMS = [
  {
    term: "Professionalism",
    definition:
      "Did the agent demonstrate active listening, understanding, and concern for the customer's situation?",
  },
  {
    term: "Empathy",
    definition:
      "Did the agent maintain a respectful, courteous, and professional tone throughout the call?",
  },
];

export const DEFINITION_EXAMPLES = {
  label: "Possible scores",
  value: "Negative, Neutral, Positive",
};
