import React from "react";
import cn from "classnames";

export enum AlertMessageColor {
  RED = "RED",
  GREEN = "GREEN",
  YELLOW = "YELLOW",
  BLUE = "BLUE",
}

export interface AlertMessageProps {
  color?: AlertMessageColor;
  title?: React.ReactNode;
  message?: React.ReactNode;
  Icon: React.ElementType;
}

const colorClassMap: Record<
  AlertMessageColor,
  Record<"outer" | "title" | "message" | "icon", string>
> = {
  [AlertMessageColor.RED]: {
    outer: "bg-red-50",
    title: "text-red-800",
    message: "text-red-700",
    icon: "text-red-400 ",
  },
  [AlertMessageColor.GREEN]: {
    outer: "bg-green-50",
    title: "text-green-800",
    message: "text-green-700",
    icon: "text-green-400",
  },
  [AlertMessageColor.YELLOW]: {
    outer: "bg-yellow-50",
    title: "text-yellow-800",
    message: "text-yellow-700",
    icon: "text-yellow-400",
  },
  [AlertMessageColor.BLUE]: {
    outer: "bg-blue-50",
    title: "text-blue-800",
    message: "text-blue-700",
    icon: "text-blue-400",
  },
};

export const AlertMessage: React.FC<AlertMessageProps> = ({
  color = AlertMessageColor.BLUE,
  title,
  message,
  Icon,
  ...props
}) => {
  const colorClasses = colorClassMap[color];

  return (
    <div
      className={cn(
        "flex gap-4 w-full p-4 rounded-md group",
        colorClasses.outer
      )}
      {...props}
    >
      <Icon
        className={cn("w-5 h-5 flex-shrink-0", colorClasses.icon)}
        aria-hidden="true"
      />
      <div className="flex-grow text-sm leading-5 space-y-2">
        {title && (
          <div className={cn("font-medium", colorClasses.title)}>{title}</div>
        )}
        {message && <div className={colorClasses.message}>{message}</div>}
      </div>
    </div>
  );
};
