import React from "react";
import { Navigate } from "react-router-dom";

import { Dashboard } from "layouts/Dashboard";
import { Calls } from "modulesV2/Calls";
import { CallSummary, RecordingType } from "modulesV2/CallSummary";
import { Tasks } from "modulesV2/Tasks";
import { Facilities } from "modulesV2/Facilities";
import { VirtualManagers } from "modulesV2/VirtualManagers";
import { Comms } from "modulesV2/Comms";
import { Settings } from "modulesV2/Settings";
import WebExRedirectPage from "pages/WebExRedirectPage";
import { Path } from "./types";

export const routes = [
  {
    path: "/",
    element: <Dashboard />,
    children: [
      {
        index: true,
        element: <Navigate to={Path.Calls} replace />,
      },
      {
        path: Path.Calls,
        element: <Calls />,
      },
      {
        path: Path.Tasks,
        element: <Tasks />,
      },
      {
        path: Path.Facilities,
        element: <Facilities />,
      },
      {
        path: Path.VirtualManagers,
        element: <VirtualManagers />,
      },
      {
        path: Path.Comms,
        element: <Comms />,
      },
      {
        path: Path.Settings,
        element: <Settings />,
      },
      {
        path: Path.Recordings,
        element: <CallSummary />,
      },
      {
        path: Path.InboundRecording,
        element: <CallSummary />,
      },
      {
        path: Path.OutboundRecording,
        element: <CallSummary recordingType={RecordingType.Outbound} />,
      },
    ],
  },
  {
    path: Path.OAuthRedirect,
    element: <WebExRedirectPage />,
  },
];
