import {
  PhoneArrowDownLeftIcon,
  PhoneArrowUpRightIcon,
} from "@heroicons/react/24/solid";
import React from "react";
import { formatShortDate } from "utils/date";
import { PhoneCallRecordingData, RecordingType } from "../../types";
import { getCallTitle } from "./utils";

type CallHeaderProps = {
  recording?: PhoneCallRecordingData;
  recordingType: RecordingType;
};

export const CallHeader = ({ recording, recordingType }: CallHeaderProps) => {
  const Icon =
    recordingType === RecordingType.Inbound
      ? PhoneArrowDownLeftIcon
      : PhoneArrowUpRightIcon;

  const renderInfo = () => {
    if (!recording) {
      return null;
    }

    return (
      <div>
        <div className="text-gray-900 text-sm font-medium">
          {getCallTitle(recording.users, recordingType)}
        </div>
        <div className="text-gray-500 text-xs">
          {formatShortDate(recording.timeCreated)}
        </div>
      </div>
    );
  };

  return (
    <div className="flex gap-2 items-start">
      <div className="rounded-full p-2.5 bg-gray-100">
        <Icon className="h-5 w-5 text-gray-900" aria-hidden="true" />
      </div>
      {renderInfo()}
    </div>
  );
};
