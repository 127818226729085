import React from "react";
import {
  ArrowTopRightOnSquareIcon,
  PhoneArrowUpRightIcon,
  PhoneXMarkIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { Tooltip } from "@mui/material";
import { Button, ButtonLink, ButtonVariant } from "componentsV2/Button";
import { getConversationCustomerDisplayName } from "utils/conversations";
import { checkIfFacilityHasMorannonId, displayName } from "utils/facility";
import { AdditionalActionsMenu } from "../AdditionalActionsMenu";
import {
  SendPaymentLinkButton,
  SendPaymentLinkButtonDisabled,
} from "../SendPaymentLinkButton";
import {
  ConversationModalContextValue,
  useConversationModal,
} from "modulesV2/ConversationModal/contexts/ConversationModalContext";
import { Conversation } from "api/types";

interface ActionsProps {
  initialFocusRef: React.MutableRefObject<HTMLButtonElement>;
}

export const Actions: React.FC<ActionsProps> = ({ initialFocusRef }) => {
  const {
    conversations,
    handleClose,
    isClosingModal,
    handleFacilityClick,
    isDialedIn,
    handleDialIn,
    dialInDisabled,
    handleHangUp,
    hangUpDisabled,
  } = useConversationModal();

  const customerDisplayName = getConversationCustomerDisplayName(
    conversations[0]
  );
  const customerPmsLink =
    conversations[0]?.customer?.pmsPaymentUrl ??
    conversations[0]?.customer?.pmsUrl;
  const pmsLinkLabel = "Open in PMS";

  const renderFacilityTitle = (
    facility: ConversationModalContextValue["conversations"][0]["facility"],
    onClick: ConversationModalContextValue["handleFacilityClick"]
  ) => {
    const facilityTitle = facility ? displayName(facility) : "Unknown facility";

    // No click handler provided, render as text
    if (!onClick) {
      return <div className="text-gray-500">{facilityTitle}</div>;
    }

    // Render as button
    return (
      <button
        type="button"
        onClick={handleFacilityClick}
        className="text-gray-500 underline underline-offset-2"
      >
        {facilityTitle}
      </button>
    );
  };

  const buttonDisableCheck = (conversation: Conversation) => {
    const { facility, facilityId, customer, customerId, customerPhoneNumber } =
      conversation;

    const conversationMatchedToFacility = typeof facilityId === "number";
    const conversationMatchedToCustomer = typeof customerId === "number";
    const facilityHasMorannonId = checkIfFacilityHasMorannonId(facility);

    const tenantHasLedgers = customer?.isActive;
    const tenantPmsId = customer?.pmsId;

    if (
      !conversationMatchedToFacility ||
      !conversationMatchedToCustomer ||
      !facilityHasMorannonId ||
      !tenantHasLedgers ||
      !tenantPmsId ||
      !customerPhoneNumber
    ) {
      let disabledMessage = "";

      if (!conversationMatchedToFacility) {
        disabledMessage = "Conversation must be matched to a facility";
      } else if (!conversationMatchedToCustomer) {
        disabledMessage = "Conversation must be matched to a customer";
      } else if (!facilityHasMorannonId) {
        disabledMessage = "Facility must have Morannon ID configured";
      } else if (!tenantHasLedgers) {
        disabledMessage = "Tenant must have active ledgers";
      } else if (!tenantPmsId) {
        disabledMessage = "Customer must have a PMS ID";
      } else if (!customerPhoneNumber) {
        disabledMessage = "Customer must have a phone number";
      }
      return disabledMessage;
    }
  };

  const renderPaymentLinkButton = () => {
    const { facilityId, customerId } = conversations[0];
    const disabledMessage = buttonDisableCheck(conversations[0]);
    if (disabledMessage) {
      return <SendPaymentLinkButtonDisabled message={disabledMessage} />;
    }

    // The buttonDisableCheck will catch the case where the facilityId, moracustomerId is undefined
    // and render the disabled button with the appropriate message
    if (!facilityId || !customerId) {
      return;
    }
    return (
      <SendPaymentLinkButton facilityId={facilityId} tenantId={customerId} />
    );
  };

  const renderDialInButton = () => {
    const { label, Icon, handleClick, disabled } = isDialedIn
      ? {
          label: "Hang up call",
          Icon: PhoneXMarkIcon,
          handleClick: handleHangUp,
          disabled: hangUpDisabled,
        }
      : {
          label: "Dial in",
          Icon: PhoneArrowUpRightIcon,
          handleClick: handleDialIn,
          disabled: dialInDisabled,
        };

    return (
      <Tooltip title={label} placement="top">
        <span>
          <Button
            type="button"
            onClick={handleClick}
            aria-label={label}
            Icon={Icon}
            variant={ButtonVariant.WHITE}
            disabled={disabled}
          />
        </span>
      </Tooltip>
    );
  };

  return (
    <div className="flex justify-between items-start gap-4 pb-4 border-b border-gray-200">
      <div className="text-sm">
        <div className="text-gray-900 font-semibold">{customerDisplayName}</div>
        {renderFacilityTitle(conversations[0].facility, handleFacilityClick)}
      </div>
      <div className="flex -mx-3 -my-2">
        <AdditionalActionsMenu />
        {renderPaymentLinkButton()}
        {customerPmsLink && (
          <Tooltip title={pmsLinkLabel} placement="top">
            <ButtonLink
              href={customerPmsLink}
              target="_blank"
              rel="noreferrer"
              Icon={ArrowTopRightOnSquareIcon}
              variant={ButtonVariant.WHITE}
              aria-label={pmsLinkLabel}
            />
          </Tooltip>
        )}
        {renderDialInButton()}
        <Button
          ref={initialFocusRef}
          type="button"
          onClick={handleClose}
          aria-label="Close comms modal"
          Icon={XMarkIcon}
          variant={ButtonVariant.WHITE}
          loading={isClosingModal}
        />
      </div>
    </div>
  );
};
