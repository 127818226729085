import {
  TaskAssignment,
  RealtimeTaskType,
  CallScriptsPrefill,
  User,
} from "api/types";
import { formatE164ToDisplay } from "utils/phoneNumber";
import { getTenantPrimaryPhoneNumber } from "utils/tenant";

export enum CallType {
  WebEx,
  Twilio,
}

export const getCallType = (taskAssignment: TaskAssignment): CallType => {
  if (taskAssignment.realtimeTask.type === RealtimeTaskType.PhoneCall) {
    return CallType.Twilio;
  }
  return CallType.WebEx;
};

export const getCallScriptsPrefill = (
  task: TaskAssignment
): CallScriptsPrefill => {
  const prefillPhoneNumber =
    (task.realtimeTask.tenant &&
      getTenantPrimaryPhoneNumber(task.realtimeTask.tenant)) ||
    task.realtimeTask.phoneCall?.fromPhoneNumber;

  return {
    phoneNumber: prefillPhoneNumber
      ? formatE164ToDisplay(prefillPhoneNumber)
      : "",
  };
};

export const AUTOPASS_DELAY_MS = 8000;
export const AUTOPASS_DELAY_BUFFER_MS = 1000;

/**
 * Returns remaining time from now to autopass time (8 seconds after creation time).
 * @param receivedTime ISO string
 * @returns time remaining (ms)
 */
export const getTaskAutopassDelay = (received: string) => {
  const receivedTime = new Date(received).getTime();
  const autopassTime = receivedTime + AUTOPASS_DELAY_MS;
  const nowTime = new Date().getTime();
  return Math.max(autopassTime - nowTime, 0);
};

export const getTaskAssignmentPhone = (
  taskAssignment: TaskAssignment
): string => taskAssignment.realtimeTask.phoneCall?.facilityPhoneNumber || "";

export const removeSignatureFromUrl = (
  presignedRecordingUrl: string
): string => {
  const urlObj = new URL(presignedRecordingUrl);
  urlObj.search = "";
  return urlObj.toString();
};

export const normalizeUrl = (url: string) => {
  return url.replace(/\.s3\.[a-z0-9-]+\.amazonaws\.com/, ".s3.amazonaws.com");
};

/**
 * Returns unique users from task assignments.
 * @param taskAssignments
 * @returns array of unique users
 */
export const getUniqueUsersFromTaskAssignments = (
  taskAssignments: TaskAssignment[]
) => {
  return taskAssignments.reduce<User[]>((acc, curr) => {
    const currUserExists = acc.some((user) => user.id === curr.assignee.id);
    if (!currUserExists) {
      acc.push(curr.assignee);
    }
    return acc;
  }, []);
};
