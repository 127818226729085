import {
  BuildingOfficeIcon,
  CalendarIcon,
  ClockIcon,
  PhoneArrowDownLeftIcon,
  PhoneArrowUpRightIcon,
  UserCircleIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { CardExpandable } from "componentsV2/Card";
import { getDisplayDateMDY } from "utils/date";
import { displayName as getUserDisplayName } from "utils/user";
import { PhoneCallRecordingData, RecordingType } from "../../types";
import { FacilityButton } from "./FacilityButton";
import { TenantButton } from "./TenantButton";
import { getCallTimeRange } from "./utils";

type CallDetailsCard = {
  recording?: PhoneCallRecordingData;
  recordingId?: string;
  callDuration: number;
  recordingType: RecordingType;
};

export const CallDetailsCard = ({
  recording,
  recordingId,
  callDuration,
  recordingType,
}: CallDetailsCard) => {
  const detailsArray: {
    label: string;
    Icon: React.ElementType;
    value: React.ReactNode;
  }[] = [];

  detailsArray.push({
    label: "Facility",
    Icon: BuildingOfficeIcon,
    value: <FacilityButton facility={recording?.facility} />,
  });

  if (recording?.timeCreated) {
    detailsArray.push({
      label: "Date",
      Icon: CalendarIcon,
      value: getDisplayDateMDY(recording.timeCreated),
    });

    detailsArray.push({
      label: "Time",
      Icon: ClockIcon,
      value: getCallTimeRange(recording.timeCreated, callDuration),
    });
  }

  const agents = recording?.users || [];
  const agentNames = agents.map(getUserDisplayName).join(", ") || "Unknown";
  const AgentIcon = agents.length > 1 ? UsersIcon : UserIcon;
  detailsArray.push({
    label: "Agent",
    Icon: AgentIcon,
    value: agentNames,
  });

  detailsArray.push({
    label: "Tenant",
    Icon: UserCircleIcon,
    value: (
      <TenantButton tenant={recording?.tenant} facility={recording?.facility} />
    ),
  });

  const isInboundCall = recordingType === RecordingType.Inbound;
  const CallIcon = isInboundCall
    ? PhoneArrowDownLeftIcon
    : PhoneArrowUpRightIcon;
  const callTypeText = isInboundCall ? "Inbound" : "Outbound";
  detailsArray.push({
    label: "Call ID",
    Icon: CallIcon,
    value: `${recordingId ?? "N/A"} (${callTypeText})`,
  });

  return (
    <CardExpandable heading="Call details">
      <dl className="space-y-6">
        {detailsArray.map(({ label, Icon, value }, index) => (
          <div key={index} className="text-gray-500 text-xs flex gap-3">
            <dt className="basis-20 shrink-0">{label}</dt>
            <dd className="flex items-center gap-2">
              <Icon className="h-5 w-6 text-gray-400" aria-hidden="true" />
              <div>{value}</div>
            </dd>
          </div>
        ))}
      </dl>
    </CardExpandable>
  );
};
