import { Facility, Tenant, User } from "api/types";

export enum SidebarState {
  HIGHLIGHTS = "HIGHLIGHTS",
  TRANSCRIPT = "TRANSCRIPT",
}

export enum RecordingType {
  Inbound = "inbound",
  Outbound = "outbound",
}

export type OnMediaCanPlayThroughEventHandler = (
  e: React.SyntheticEvent<HTMLAudioElement | HTMLVideoElement>
) => void;

// Normalized required data from PhoneCallRecording, DirectPhoneCallRecording.
export type PhoneCallRecordingData = {
  presignedUrl: string;
  callTranscriptionId?: number;
  timeCreated: string;
  users: User[];
  tenant?: Tenant;
  facility?: Facility;
};
