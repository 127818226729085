import React from "react";
import {
  ArrowUpTrayIcon,
  BuildingOfficeIcon,
  CheckCircleIcon,
  CurrencyDollarIcon,
  FlagIcon,
  InformationCircleIcon,
  PhoneArrowDownLeftIcon,
  PhoneArrowUpRightIcon,
  PhoneXMarkIcon,
  PlayCircleIcon,
  XCircleIcon,
  KeyIcon,
} from "@heroicons/react/20/solid";
import { CommsEvent, CommsEventSourceType, CommsEventType } from "api/types";

import { EventMessage } from "./EventMessage";
import { EventSmall } from "./EventSmall";
import { Voicemail, VoicemailVariant } from "componentsV2/Voicemail";

interface EventProps {
  event: CommsEvent;
  voicemail?: CommsEvent;
}

enum AdditionalEventType {
  MISSED_CALL_WITH_VOICEMAIL = "MISSED_CALL_WITH_VOICEMAIL",
  DEFAULT = "DEFAULT",
}

const eventTypeMapping: Record<
  CommsEventType | AdditionalEventType,
  {
    isMessage: boolean;
    Icon: React.ElementType;
    text: string;
    showEventSource?: boolean;
    showErrorIcon?: boolean;
  }
> = {
  [CommsEventType.INBOUND_PHONE_CALL]: {
    isMessage: false,
    Icon: PhoneArrowDownLeftIcon,
    text: "Inbound phone call",
  },
  [CommsEventType.OUTBOUND_PHONE_CALL]: {
    isMessage: false,
    Icon: PhoneArrowUpRightIcon,
    text: "Outbound phone call",
  },
  [CommsEventType.COUNTER_CALL]: {
    isMessage: false,
    Icon: BuildingOfficeIcon,
    text: "Counter call",
  },
  [CommsEventType.MISSED_CALL]: {
    isMessage: false,
    Icon: PhoneXMarkIcon,
    text: "Missed call",
  },
  [CommsEventType.VOICEMAIL]: {
    isMessage: false,
    Icon: PlayCircleIcon,
    text: "Received voicemail",
  },
  [CommsEventType.FOLLOW_UP_TEXT]: {
    isMessage: true,
    Icon: FlagIcon,
    text: "Lead Followup Automation",
  },
  [CommsEventType.FOLLOW_UP_CALL]: {
    isMessage: false,
    Icon: PhoneArrowUpRightIcon,
    text: "Lead followup outbound robo-call",
  },
  [CommsEventType.VERA_TEXT]: {
    isMessage: true,
    Icon: CurrencyDollarIcon,
    text: "Deliquency Collection",
  },
  [CommsEventType.VERA_CALL]: {
    isMessage: false,
    Icon: PhoneArrowUpRightIcon,
    text: "Vera outbound robo-call",
  },
  [CommsEventType.VERA_PAYMENT_ERROR]: {
    isMessage: false,
    Icon: XCircleIcon,
    text: "Vera Payment Failed",
  },
  [CommsEventType.VERA_PAYMENT_SUCCESS]: {
    isMessage: false,
    Icon: CheckCircleIcon,
    text: "Vera Payment Successful",
  },
  [CommsEventType.SEND_MOVE_OUT_LINK_SUCCESS]: {
    isMessage: true,
    Icon: ArrowUpTrayIcon,
    text: "Move out Link",
    showEventSource: true,
  },
  [CommsEventType.SEND_MOVE_OUT_LINK_ERROR]: {
    isMessage: true,
    Icon: ArrowUpTrayIcon,
    text: "Move out Link",
    showEventSource: true,
    showErrorIcon: true,
  },
  [AdditionalEventType.MISSED_CALL_WITH_VOICEMAIL]: {
    isMessage: false,
    Icon: PhoneXMarkIcon,
    text: "Missed call (voicemail)",
  },
  [AdditionalEventType.DEFAULT]: {
    isMessage: false,
    Icon: InformationCircleIcon,
    text: "Unhandled CommsEvent type",
  },
  [CommsEventType.GATE_CODE_SUCCESS]: {
    isMessage: true,
    Icon: KeyIcon,
    text: "Facility Access",
    showEventSource: true,
  },
  [CommsEventType.GATE_CODE_ERROR]: {
    isMessage: true,
    Icon: KeyIcon,
    text: "Facility Access",
    showEventSource: true,
    showErrorIcon: true,
  },
  [CommsEventType.SEND_PAYMENT_LINK_SUCCESS]: {
    isMessage: true,
    Icon: CurrencyDollarIcon,
    text: "Payment Link",
    showEventSource: true,
  },
  [CommsEventType.SEND_PAYMENT_LINK_ERROR]: {
    isMessage: true,
    Icon: CurrencyDollarIcon,
    text: "Payment Link",
    showEventSource: true,
    showErrorIcon: true,
  },
};

export const Event: React.FC<EventProps> = ({ event, voicemail }) => {
  let mappedValues;
  const isAutomation = event.source === CommsEventSourceType.AI_AUTOMATION;

  if (event.type === CommsEventType.MISSED_CALL && voicemail) {
    mappedValues =
      eventTypeMapping[AdditionalEventType.MISSED_CALL_WITH_VOICEMAIL];
  } else {
    mappedValues =
      eventTypeMapping[event.type] ||
      eventTypeMapping[AdditionalEventType.DEFAULT];
  }

  if (mappedValues.isMessage) {
    let title;
    if (mappedValues.showEventSource) {
      title = (
        <>
          <span>{mappedValues.text}</span>
          <span className="text-gray-400">
            {" "}
            ({isAutomation ? "AI Automation" : "Virtual Manager"})
          </span>
        </>
      );
    } else {
      title = mappedValues.text;
    }

    const error = mappedValues.showErrorIcon;

    return (
      <EventMessage
        Icon={mappedValues.Icon}
        title={title}
        content={event.content}
        time={event.timeCreated}
        error={error}
      />
    );
  }

  let text, voicemailUrl, callTranscript;

  if (event.type === CommsEventType.VOICEMAIL) {
    // For standalone voicemail events, content field is used for voicemail url
    text = mappedValues.text;
    voicemailUrl = event.content;
    callTranscript = event.callTranscript;
  } else {
    // For combined missed call/voicemail events, voicemail url is provided via a prop
    text = event.content || mappedValues.text;
    voicemailUrl = voicemail?.content;
    callTranscript = voicemail?.callTranscript;
  }

  return (
    <>
      <EventSmall
        Icon={mappedValues.Icon}
        text={text}
        time={event.timeCreated}
      />
      {voicemailUrl && (
        <div className="mt-2">
          <Voicemail
            recordingUrl={voicemailUrl}
            variant={VoicemailVariant.SMALL}
            callTranscript={callTranscript}
          />
        </div>
      )}
    </>
  );
};
