import client from "./client";

import {
  CallStatus,
  PhoneToken,
  PhoneCall,
  DirectPhoneCall,
  Paginated,
  PhoneCallRecording,
  CallTranscription,
  DirectPhoneCallRecording,
  CreateCallTranscriptSummaryFeedbackBody,
  CallTranscriptSummaryFeedback,
} from "./types";

const DEFAULT_PAGE_SIZE = 100;

// Handles auth for Twilio voice-sdk and conversations
export const phoneToken = () => client.get<PhoneToken>("v1/phone/token");

export const getPhoneCall = (id: string | number) =>
  client.get<PhoneCall>(`/v1/phone-calls/${id}`);

export const endCall = (id: number) =>
  client.patch<PhoneCall>(`/v1/phone-calls/${id}`, {
    status: CallStatus.Ended,
  });

export const getActiveDirectPhoneCalls = (
  limit = DEFAULT_PAGE_SIZE,
  offset = 0
) =>
  client.get<Paginated<DirectPhoneCall>>(
    "v1/organizations/current/direct-phone-calls",
    {
      params: { limit: limit, offset: offset, time_completed: "null" },
    }
  );

export const getInboundRecording = (id: string | number) =>
  client.get<PhoneCallRecording>(`/v1/phone-calls/${id}/recording`);

export const getOutboundRecording = (id: string | number) =>
  client.get<DirectPhoneCallRecording>(
    `/v1/direct-phone-calls/${id}/recording`
  );

export const getCallTranscript = (id: string | number) =>
  client.get<CallTranscription>(`v1/call-transcripts/${id}`);

export const createCallTranscriptSummaryFeedback = (
  id: string | number,
  data: CreateCallTranscriptSummaryFeedbackBody
) =>
  client.post<CallTranscriptSummaryFeedback>(
    `v1/call-transcript-summaries/${id}/feedbacks`,
    data
  );
