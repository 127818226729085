import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import {
  AlertMessageProps,
  AlertMessageColor,
} from "componentsV2/AlertMessage";

export const readyProps: AlertMessageProps = {
  Icon: CheckCircleIcon,
  color: AlertMessageColor.GREEN,
  title: "Customer is ready to receive documents",
  message:
    "Please select the unit and document type you’d like to send to the iPad",
};

export const notReadyProps: AlertMessageProps = {
  Icon: ExclamationTriangleIcon,
  color: AlertMessageColor.YELLOW,
  title: "Customer is not ready to receive documents",
  message:
    "Please instruct the customer to tap the 'E-Sign Document' tile on the iPad and then tap 'Receive my documents' button",
};
