import React from "react";
import { AsyncTaskResourceType } from "api/types";
import {
  ButtonRounded,
  ButtonRoundedVariant,
} from "componentsV2/ButtonRounded";
import { useAsyncTaskFilters } from "hooks/useAsyncTaskFilters";

interface FilterToggleProps {
  label: string;
  resourcetype: AsyncTaskResourceType;
}

export const FilterToggle: React.FC<FilterToggleProps> = ({
  label,
  resourcetype,
}) => {
  const { asyncTaskFilters, setResourceTypeFilter } = useAsyncTaskFilters();

  const isActive = asyncTaskFilters.resourcetype === resourcetype;

  const handleClick: React.MouseEventHandler = () => {
    setResourceTypeFilter(isActive ? undefined : resourcetype);
  };

  return (
    <ButtonRounded
      type="button"
      onClick={handleClick}
      role="switch"
      aria-checked={isActive}
      aria-label={`Toggle ${label} filter`}
      variant={
        isActive ? ButtonRoundedVariant.PRIMARY : ButtonRoundedVariant.SECONDARY
      }
    >
      {label}
    </ButtonRounded>
  );
};
