import React, { useState } from "react";
import { addUserToConversation } from "api/conversations";
import { Conversation, Facility, Tenant } from "api/types";
import {
  ButtonMenu,
  ButtonMenuAction,
  ButtonMenuActionType,
} from "componentsV2/ButtonMenu";
import { useDispatch, useSelector, useStore } from "hooks/redux";
import { useTwilio } from "modules/Twilio";
import { ConversationModal } from "modulesV2/ConversationModal";
import { FacilityInfo } from "modulesV2/FacilityInfo";
import { useSnackbar } from "modulesV2/Snackbar";
import { connectToOutgoingPhoneCall } from "services/connectCalls";
import {
  getConversations,
  handleOpenConversationException,
} from "services/conversations";
import {
  getTenantDisplayName,
  getTenantPrimaryPhoneNumber,
} from "utils/tenant";
import { agentSelector } from "state/agentSlice";
import {
  isDialedInSelector,
  tasksLocalStateSelector,
  updateTaskAssignmentLocalState,
} from "state/tasksSlice";
import { isDialInEnabled } from "./utils";

type TenantButtonProps = {
  facility?: Facility;
  tenant?: Tenant;
};

export const TenantButton = ({ facility, tenant }: TenantButtonProps) => {
  const snacks = useSnackbar();
  const { showErrorSnack } = snacks;
  const { disconnectPhoneCall } = useTwilio();
  const dispatch = useDispatch();
  const store = useStore();
  const { id: userId } = useSelector(agentSelector);
  const isDialedIn = useSelector(isDialedInSelector);
  const { dialedInPhoneNumber } = useSelector(tasksLocalStateSelector);

  const [facilityInfoOpen, setFacilityInfoOpen] = useState(false);
  const [conversations, setConversations] = useState<
    Conversation[] | undefined
  >(undefined);
  const [conversationOpen, setConversationOpen] = useState(false);
  const [isJoiningConversation, setIsJoiningConversation] = useState(false);

  const tenantDisplayName = getTenantDisplayName(tenant);
  // Early exit if no tenant, renders "Unknown"
  if (!tenant) {
    return (
      <span className="text-gray-500 text-xs font-normal">
        {tenantDisplayName}
      </span>
    );
  }

  const tenantPrimaryPhoneNumber = getTenantPrimaryPhoneNumber(tenant);
  const dialInParams = {
    phoneNumber: tenantPrimaryPhoneNumber,
    facilityId: tenant?.facilityId,
    tenantId: tenant?.id,
    userId,
  };
  const dialInEnabled = isDialInEnabled(dialInParams);

  const handleDialIn = async () => {
    if (dialInEnabled) {
      try {
        await connectToOutgoingPhoneCall({ ...dialInParams, store, snacks });
      } catch (e) {
        showErrorSnack(`An error occurred while connecting to call: ${e}`);
      }
    } else {
      showErrorSnack("Dialing in is not supported");
    }
  };

  const handleHangUp = async () => {
    try {
      await disconnectPhoneCall();
      dispatch(updateTaskAssignmentLocalState({}));
    } catch (e) {
      showErrorSnack("An error occurred while hanging up.");
    }
  };

  const getTenantConversations = async (): Promise<Conversation[]> => {
    if (conversations) {
      return conversations;
    }
    return getConversations(tenant);
  };

  const handleOpenConversation = async () => {
    try {
      setIsJoiningConversation(true);
      const tenantConversations = await getTenantConversations();
      setConversations(tenantConversations);
      await Promise.all(
        tenantConversations.map((conversation) =>
          addUserToConversation({ conversationId: conversation.id, userId })
        )
      );
      setConversationOpen(true);
    } catch (e) {
      handleOpenConversationException(e, showErrorSnack);
    } finally {
      setIsJoiningConversation(false);
    }
  };

  const handleCloseConversation = async () => {
    // If dialed-in to active conversation, end call
    if (dialedInPhoneNumber === tenantPrimaryPhoneNumber) {
      await handleHangUp();
    }
    setConversationOpen(false);
  };

  const actions: ButtonMenuAction[] = [];

  if (isJoiningConversation) {
    actions.push({
      type: ButtonMenuActionType.Button,
      label: "View comms",
      disabled: true,
      disabledMessage: "Joining conversation...",
    });
  } else {
    actions.push({
      type: ButtonMenuActionType.Button,
      label: "View comms",
      onClick: handleOpenConversation,
    });
  }

  if (tenant.pmsUrl) {
    actions.push({
      type: ButtonMenuActionType.Link,
      label: "Open in PMS",
      href: tenant.pmsUrl,
    });
  } else {
    actions.push({
      type: ButtonMenuActionType.Button,
      label: "Open in PMS",
      disabled: true,
      disabledMessage: "No PMS URL available",
    });
  }

  return (
    <>
      <ButtonMenu
        actions={actions}
        buttonNode={
          <button
            type="button"
            className="text-gray-500 text-xs font-normal hover:underline"
          >
            {tenantDisplayName}
          </button>
        }
      />
      {facility && (
        <FacilityInfo
          facility={facility}
          modal
          modalActive={facilityInfoOpen}
          handleCloseModal={() => setFacilityInfoOpen(false)}
        />
      )}
      {conversations && (
        <ConversationModal
          active={conversationOpen}
          conversations={conversations}
          handleCloseModal={handleCloseConversation}
          handleFacilityClick={
            facility ? () => setFacilityInfoOpen(true) : undefined
          }
          isDialedIn={isDialedIn}
          dialInDisabled={!dialInEnabled}
          handleDialIn={handleDialIn}
          handleHangUp={handleHangUp}
        />
      )}
    </>
  );
};
