import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import React, { useMemo } from "react";
import { sendMoveOutLink, sendTenantGateCode } from "api/tenants";
import { FeatureName } from "api/types";
import { Button, ButtonProps, ButtonVariant } from "componentsV2/Button";
import {
  ButtonMenu,
  ButtonMenuAction,
  ButtonMenuActionType,
} from "componentsV2/ButtonMenu";
import { useSelector } from "hooks/redux";
import { useSnackbar } from "modulesV2/Snackbar";
import { agentSelector } from "state/agentSlice";
import { isOrganizationFeatureEnabled } from "utils/organization";
import { useConversationModal } from "../../../../contexts/ConversationModalContext";

export const AdditionalActionsMenu: React.FC = () => {
  const agent = useSelector(agentSelector);
  const { showErrorSnack } = useSnackbar();
  const {
    conversations,
    isSendingGateCode,
    setIsSendingGateCode,
    isSendingMoveOutLink,
    setIsSendingMoveOutLink,
  } = useConversationModal();
  const { customerId } = conversations[0];

  const sendGateCodeAction = useMemo<ButtonMenuAction>(() => {
    const label = "Send Facility Access Information";

    if (typeof customerId !== "number") {
      return {
        type: ButtonMenuActionType.Button,
        label,
        disabled: true,
        disabledMessage: "Conversation must be matched to a customer",
      };
    }

    if (isSendingGateCode) {
      return {
        type: ButtonMenuActionType.Button,
        label,
        disabled: true,
        disabledMessage: "Facility Access Information is being sent",
      };
    }

    const onClick = async () => {
      if (isSendingGateCode) return;

      try {
        setIsSendingGateCode(true);
        await sendTenantGateCode(customerId);
      } catch (e) {
        showErrorSnack(
          e instanceof Error
            ? e.message
            : "Unable to send Facility Access Information"
        );
        setIsSendingGateCode(false);
      }
    };

    return { type: ButtonMenuActionType.Button, label, onClick };
  }, [customerId, isSendingGateCode]);

  const isSendGateCodeEnabled =
    !!agent.organization &&
    isOrganizationFeatureEnabled(agent.organization, [FeatureName.GateCode]);

  const sendMoveOutLinkAction = useMemo<ButtonMenuAction>(() => {
    const label = "Send Move Out Link";

    // Disabled, no customer matched to conversation
    if (typeof customerId !== "number") {
      return {
        type: ButtonMenuActionType.Button,
        label,
        disabled: true,
        disabledMessage: "Conversation must be matched to a customer",
      };
    }

    if (isSendingMoveOutLink) {
      return {
        type: ButtonMenuActionType.Button,
        label,
        disabled: true,
        disabledMessage: "Move out link is being sent",
      };
    }

    const onClick = async () => {
      if (isSendingMoveOutLink) return;

      try {
        setIsSendingMoveOutLink(true);
        await sendMoveOutLink(customerId);
      } catch (e) {
        showErrorSnack(
          e instanceof Error ? e.message : "Unable to send move out link"
        );
      }
    };

    return { type: ButtonMenuActionType.Button, label, onClick };
  }, [customerId, isSendingMoveOutLink]);

  const isSendMoveOutLinkEnabled =
    !!agent.organization &&
    isOrganizationFeatureEnabled(agent.organization, [
      FeatureName.SendMoveOutLink,
    ]);

  const buttonMenuActions = [];

  if (isSendGateCodeEnabled) {
    buttonMenuActions.push(sendGateCodeAction);
  }

  if (isSendMoveOutLinkEnabled) {
    buttonMenuActions.push(sendMoveOutLinkAction);
  }

  const buttonProps: ButtonProps = {
    type: "button",
    Icon: EllipsisHorizontalIcon,
    variant: ButtonVariant.WHITE,
    "aria-label": "Show additional actions",
  };

  // Render null if no actions are enabled
  if (buttonMenuActions.length === 0) {
    return <Button {...buttonProps} disabled />;
  }

  return (
    <ButtonMenu
      actions={buttonMenuActions}
      buttonNode={<Button {...buttonProps} />}
    />
  );
};
