import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import cn from "classnames";
import { Tooltip } from "@mui/material";
import {
  ButtonMenuActionType,
  ButtonMenuAction,
  ButtonMenuAlignment,
} from "./types";

interface ButtonMenuProps {
  buttonNode: React.ReactNode;
  actions?: ButtonMenuAction[];
  alignment?: ButtonMenuAlignment;
}

export const ButtonMenu: React.FC<ButtonMenuProps> = ({
  buttonNode,
  actions,
  alignment = ButtonMenuAlignment.Right,
}) => {
  const renderButtonActionMenuItem = (action: ButtonMenuAction) => {
    return (
      <Menu.Item>
        {({ active }) => {
          const sharedActionClassNames =
            "block px-4 py-2 text-sm w-full text-left";

          switch (action.type) {
            case ButtonMenuActionType.Link: {
              const { label, href } = action;
              return (
                <a
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={cn(
                    sharedActionClassNames,
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                  )}
                >
                  {label}
                </a>
              );
            }
            case ButtonMenuActionType.Button:
            default: {
              // Disabled action
              if (action.disabled) {
                const { label, disabledMessage } = action;
                return (
                  <Tooltip title={disabledMessage} placement="right">
                    <div>
                      <button
                        type="button"
                        className={cn(sharedActionClassNames, "text-gray-500", {
                          "bg-gray-100": active,
                        })}
                        disabled
                      >
                        {label}
                      </button>
                    </div>
                  </Tooltip>
                );
              }

              // Enabled action
              const { label, onClick } = action;
              return (
                <button
                  type="button"
                  onClick={onClick}
                  className={cn(
                    sharedActionClassNames,
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                  )}
                >
                  {label}
                </button>
              );
            }
          }
        }}
      </Menu.Item>
    );
  };

  return (
    <Menu as="div" className="relative w-max">
      <Menu.Button as={Fragment}>{buttonNode}</Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={cn(
            "w-max max-w-[200px] absolute z-20 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden focus:outline-none",
            {
              "left-0": alignment === ButtonMenuAlignment.Left,
              "right-0": alignment === ButtonMenuAlignment.Right,
            }
          )}
        >
          {actions?.map((action, index) => (
            <Fragment key={index}>
              {renderButtonActionMenuItem(action)}
            </Fragment>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
