import React from "react";

interface AudioProps extends React.AudioHTMLAttributes<HTMLAudioElement> {
  audioClassName?: string;
  sources?: React.SourceHTMLAttributes<HTMLSourceElement>[];
}

export const Audio: React.FC<AudioProps> = ({
  className,
  audioClassName = "w-[395px] max-w-full h-[45px]",
  sources = [],
  ...audioProps
}) => (
  <div className={className}>
    <audio className={audioClassName} controls {...audioProps}>
      {sources.map((source, index) => (
        <source key={index} {...source} />
      ))}
      Your browser does not support the audio element.
    </audio>
  </div>
);
