import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CallQueue } from "api/types";
import { RootState } from "store";

const initialState: {
  data?: CallQueue;
} = {};

export const callQueueSlice = createSlice({
  name: "callQueueSlice",
  initialState,
  reducers: {
    updateCallQueueData: (state, action: PayloadAction<CallQueue>) => {
      const currentQueueSequenceId = state.data?.queueSequenceId;
      if (
        typeof currentQueueSequenceId !== "number" ||
        action.payload.queueSequenceId > currentQueueSequenceId
      ) {
        state.data = action.payload;
      }
    },
  },
});

export const callQueueDataSelector = (state: RootState) => state.callQueue.data;

export const { updateCallQueueData } = callQueueSlice.actions;

export default callQueueSlice.reducer;
