// Differs from FeedbackSubject since Scoring covers
// both AGENT_COMPETENCY and CALL_SENTIMENT
export enum FeedbackCategory {
  Summary = "Summary",
  Scoring = "Scoring",
}

export enum FeedbackInputName {
  SummaryRating = "summary-rating",
  SummaryDetails = "summary-details",
  ScoringSentimentRating = "scoring-sentiment-rating",
  ScoringCompetencyRating = "scoring-competency-rating",
  ScoringDetails = "scoring-details",
}

export enum FeedbackModalState {
  Inactive = "Inactive",
  Rating = "Rating",
  Details = "Details",
  Success = "Success",
}
