import { BoltIcon, ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { AgentNotableCall } from "api/types";
import {
  AlertMessageColor,
  AlertMessageProps,
} from "componentsV2/AlertMessage";

export const NOTABLE_CALLS_ALERT_MESSAGE_PROPS: Record<
  AgentNotableCall,
  | (Pick<AlertMessageProps, "Icon" | "color"> & {
      title: string;
      definition: string;
    })
  | undefined
> = {
  [AgentNotableCall.SHOUTOUT_OPPORTUNITY]: {
    Icon: BoltIcon,
    title: "Shoutout opportunity",
    definition:
      "The agent went above and beyond, significantly improving the customer’s experience. This includes more than just a customer being happy because they received a financially beneficial outcome.",
    color: AlertMessageColor.GREEN,
  },
  [AgentNotableCall.SPOTLIGHT_CHECK]: {
    Icon: ExclamationTriangleIcon,
    title: "Spotlight check",
    definition:
      "Highlights an agents performance concern (e.g., if the agent made a situation worse). Additionally, these are company-level opportunities for refining policies, processes, or systems.",
    color: AlertMessageColor.RED,
  },
  [AgentNotableCall.NEUTRAL]: undefined,
};
