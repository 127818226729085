import { FeedbackType } from "api/types";
import { FeedbackCategory, FeedbackInputName } from "./types";

export const FEEDBACK_VALUES: { value: FeedbackType; label: string }[] = [
  { value: FeedbackType.GOOD, label: "👍 Good" },
  { value: FeedbackType.BAD, label: "👎 Bad" },
];

export const FEEDBACK_CATEGORY_RECORD: Record<
  FeedbackCategory,
  {
    promptLabel: string;
    formTitle: string;
    radioGroups: { label: string; name: FeedbackInputName }[];
    detailsInputName: FeedbackInputName;
  }
> = {
  [FeedbackCategory.Summary]: {
    promptLabel: "How is the AI summary? Give feedback",
    formTitle: "Summary feedback",
    radioGroups: [
      {
        label: "How is the AI summary? Tell us about your experience",
        name: FeedbackInputName.SummaryRating,
      },
    ],
    detailsInputName: FeedbackInputName.SummaryDetails,
  },
  [FeedbackCategory.Scoring]: {
    promptLabel: "How is the AI scoring? Give feedback",
    formTitle: "Scoring feedback",
    radioGroups: [
      {
        label: "How well did the AI score <strong>agent competency</strong>?",
        name: FeedbackInputName.ScoringCompetencyRating,
      },
      {
        label: "How well did the AI score <strong>call sentiment</strong>?",
        name: FeedbackInputName.ScoringSentimentRating,
      },
    ],
    detailsInputName: FeedbackInputName.ScoringDetails,
  },
};
