import clsx from "clsx";
import React, { useId } from "react";
import { ErrorLabelBase } from "../ErrorLabelBase";
import { LabelBase } from "../LabelBase";

export interface RadioGroupProps
  extends React.FieldsetHTMLAttributes<HTMLFieldSetElement> {
  label?: React.ReactNode;
  options: { value: string; label: string }[];
  defaultCheckedValue?: string;
  required?: boolean;
  error?: string;
}

export const RadioGroup = ({
  id,
  name,
  label,
  options,
  defaultCheckedValue,
  required,
  error,
  ...fieldsetProps
}: RadioGroupProps) => {
  const defaultId = useId();
  const radioGroupId = id ?? defaultId;
  const errorLabelId = `${radioGroupId}-error`;

  const hasError = !!error;
  const fieldsetErrorProps = {
    ...(hasError
      ? { "aria-invalid": true, "aria-describedby": errorLabelId }
      : {}),
  };

  return (
    <fieldset {...fieldsetProps} {...fieldsetErrorProps}>
      {label && (
        <LabelBase as="legend" className="mb-4" required={required}>
          {label}
        </LabelBase>
      )}
      <div className="space-y-4">
        {options.map((option, index) => {
          const optionId = `${radioGroupId}-${index}`;
          return (
            <div key={optionId} className="flex items-center gap-2">
              <input
                type="radio"
                id={optionId}
                name={name}
                value={option.value}
                defaultChecked={defaultCheckedValue === option.value}
                required={required}
                className={clsx(
                  "relative size-4 cursor-pointer appearance-none rounded-full",
                  "border border-gray-300 bg-white",
                  "before:absolute before:inset-1 before:rounded-full before:bg-white",
                  "checked:border-navy-700 checked:bg-navy-700 [&:not(:checked)]:before:hidden",
                  "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-navy-700",
                  "disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400",
                  "forced-colors:appearance-auto forced-colors:before:hidden"
                )}
              />
              <LabelBase htmlFor={optionId} className="cursor-pointer">
                {option.label}
              </LabelBase>
            </div>
          );
        })}
      </div>
      {hasError && <ErrorLabelBase id={errorLabelId}>{error}</ErrorLabelBase>}
    </fieldset>
  );
};
