import {
  POSITIVE_SENTIMENT_VALUES,
  NEGATIVE_SENTIMENT_VALUES,
  VALID_SENTIMENT_VALUES,
} from "./constants";
import { SentimentValue } from "./types";

export const isValidSentiment = (sentiment?: string): sentiment is string =>
  !!(sentiment && VALID_SENTIMENT_VALUES.includes(sentiment));

export const getSentimentValue = (sentiment?: string): SentimentValue => {
  if (isValidSentiment(sentiment)) {
    if (POSITIVE_SENTIMENT_VALUES.includes(sentiment)) {
      return SentimentValue.POSITIVE;
    }
    if (NEGATIVE_SENTIMENT_VALUES.includes(sentiment)) {
      return SentimentValue.NEGATIVE;
    }
  }

  return SentimentValue.NEUTRAL;
};
