import React from "react";
import { CallTranscriptSummary } from "api/types";
import { Badge } from "componentsV2/Badges/Badge";
import { CardExpandable } from "componentsV2/Card";
import { HeadingPopover, PopoverPosition } from "componentsV2/HeadingPopover";
import { DefinitionPopover } from "../DefinitionPopover";
import {
  DEFINITION_ITEMS,
  DEFINITION_EXAMPLES,
  SENTIMENT_VALUE_RECORD,
} from "./constants";
import { isValidSentiment, getSentimentValue } from "./utils";
import { Blockquote } from "componentsV2/Blockquote";

type SentimentDetailProps = {
  label: string;
  sentiment?: string;
  analysis?: string;
  showAnalysis?: boolean;
};

const SentimentDetail = ({
  label,
  sentiment,
  analysis,
  showAnalysis,
}: SentimentDetailProps) => {
  const sentimentIsValid = isValidSentiment(sentiment);
  const sentimentValue = getSentimentValue(sentiment);
  const { badgeVariant, blockquoteColor } =
    SENTIMENT_VALUE_RECORD[sentimentValue];

  const renderSentiment = () => {
    if (!sentimentIsValid) {
      return <div className="text-gray-400">Not available</div>;
    }

    return <Badge variant={badgeVariant} text={sentiment} />;
  };

  const renderAnalysis = () => {
    if (!showAnalysis) {
      return null;
    }

    if (!analysis) {
      return (
        <div className="text-xs text-gray-400 pl-4">Analysis not available</div>
      );
    }

    return <Blockquote color={blockquoteColor}>{analysis}</Blockquote>;
  };

  return (
    <div className="space-y-6">
      <div className="flex gap-2 justify-between">
        <div className="text-xs text-gray-500">{label}</div>
        <div>{renderSentiment()}</div>
      </div>
      {renderAnalysis()}
    </div>
  );
};

type CallSentimentCardProps = Partial<
  Pick<
    CallTranscriptSummary,
    | "startingSentiment"
    | "endingSentiment"
    | "startingSentimentAnalysis"
    | "endingSentimentAnalysis"
  >
>;

export const CallSentimentCard = ({
  startingSentiment,
  startingSentimentAnalysis,
  endingSentiment,
  endingSentimentAnalysis,
}: CallSentimentCardProps) => {
  const heading = (
    <HeadingPopover
      popover={
        <DefinitionPopover
          items={DEFINITION_ITEMS}
          examples={DEFINITION_EXAMPLES}
        />
      }
      popoverPosition={PopoverPosition.LEFT}
    >
      Agent sentiment
    </HeadingPopover>
  );

  const previewContent = (
    <div className="space-y-6">
      <SentimentDetail
        label="Beginning Sentiment"
        sentiment={startingSentiment}
      />
      <SentimentDetail label="Ending Sentiment" sentiment={endingSentiment} />
    </div>
  );

  return (
    <CardExpandable heading={heading} previewContent={previewContent}>
      <div className="space-y-6">
        <SentimentDetail
          label="Beginning Sentiment"
          sentiment={startingSentiment}
          analysis={startingSentimentAnalysis}
          showAnalysis
        />
        <SentimentDetail
          label="Ending Sentiment"
          sentiment={endingSentiment}
          analysis={endingSentimentAnalysis}
          showAnalysis
        />
      </div>
    </CardExpandable>
  );
};
