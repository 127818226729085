import { Transition } from "@headlessui/react";
import cn from "classnames";
import React, { useId, useState, useRef, useEffect } from "react";
import { Card } from "componentsV2/Card";

export enum PopoverPosition {
  BOTTOM = "BOTTOM",
  TOP = "TOP",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export interface HeadingPopoverProps {
  children: React.ReactNode;
  popover: React.ReactNode;
  popoverPosition?: PopoverPosition;
}

const popoverPositionClasses: Record<PopoverPosition, string> = {
  [PopoverPosition.BOTTOM]: "top-full left-0 translate-y-4",
  [PopoverPosition.TOP]:
    "md:top-initial md:bottom-full md:left-0 md:-translate-y-4",
  [PopoverPosition.LEFT]:
    "md:left-initial md:translate-y-0 md:right-full md:top-0 md:-translate-x-4",
  [PopoverPosition.RIGHT]:
    "md:translate-y-0 md:left-full md:top-0 md:translate-x-4",
};

export const HeadingPopover: React.FC<HeadingPopoverProps> = ({
  children,
  popover,
  popoverPosition = PopoverPosition.LEFT,
}) => {
  const popoverId = useId();
  const popoverRef = useRef<HTMLDivElement>(null);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const isPopoverVisible = isButtonClicked || isButtonHovered;

  const handleClickaway = (event: MouseEvent) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target as Node)
    ) {
      setIsButtonClicked(false);
    }
  };

  useEffect(function initClickawayListener() {
    document.addEventListener("mousedown", handleClickaway);
    return () => {
      document.removeEventListener("mousedown", handleClickaway);
    };
  }, []);

  return (
    <div className="relative w-max">
      <button
        type="button"
        aria-expanded={isPopoverVisible}
        aria-controls={popoverId}
        aria-haspopup="true"
        aria-label="Open details"
        onClick={(e) => {
          e.stopPropagation();
          setIsButtonClicked(true);
        }}
        onMouseEnter={() => setIsButtonHovered(true)}
        onMouseLeave={() => setIsButtonHovered(false)}
        className="px-2 py-1 group-hover:bg-black/5 rounded-md -mx-2 -my-1 text-sm font-medium"
      >
        {children}
      </button>
      <div
        id={popoverId}
        ref={popoverRef}
        className={cn("absolute z-10", popoverPositionClasses[popoverPosition])}
      >
        <Transition show={isPopoverVisible}>
          <Transition.Child
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Card>{popover}</Card>
          </Transition.Child>
        </Transition>
      </div>
    </div>
  );
};
