import { createTaskAssignment } from "api/tasks";
import { CallQueueItem, RealtimeTaskType, Role, User } from "api/types";
import { getElapsedTimeSeconds } from "utils/date";
import { CallQueueFilter } from "./types";

export const getInitialCallQueueFilter = (role: Role): CallQueueFilter => {
  switch (role) {
    case Role.CounterOnly:
      return CallQueueFilter.CounterCallsOnly;
    case Role.PhoneOnly:
      return CallQueueFilter.PhoneCallsOnly;
    default:
      return CallQueueFilter.AllCalls;
  }
};

const isCallQueueItemCounterCall = (item: CallQueueItem) =>
  [RealtimeTaskType.CounterActivity, RealtimeTaskType.ButtonPressed].includes(
    item.type
  );

export const isCallQueueItemPhoneCall = (item: CallQueueItem) =>
  item.type === RealtimeTaskType.PhoneCall;

export const checkIfCallQueueItemShouldNotifyAgent = (
  item: CallQueueItem,
  role: Role
) => {
  switch (role) {
    case Role.CounterOnly:
      return isCallQueueItemCounterCall(item);
    case Role.PhoneOnly:
      return isCallQueueItemPhoneCall(item);
    default:
      return true;
  }
};

export const getNewItemNotificationText = (item: CallQueueItem) => {
  const callTypeText = isCallQueueItemPhoneCall(item) ? "phone" : "counter";
  return `New ${callTypeText} call added to queue`;
};

export const getItemsFilteredByRole = (
  items: CallQueueItem[],
  role: Role
): CallQueueItem[] => {
  switch (role) {
    case Role.CounterOnly:
      return items.filter(isCallQueueItemCounterCall);
    case Role.PhoneOnly:
      return items.filter(isCallQueueItemPhoneCall);
    default:
      return items;
  }
};

export const getItemsFilteredByTypeFilter = (
  items: CallQueueItem[],
  typeFilter: CallQueueFilter
): CallQueueItem[] => {
  switch (typeFilter) {
    case CallQueueFilter.CounterCallsOnly:
      return items.filter(isCallQueueItemCounterCall);
    case CallQueueFilter.PhoneCallsOnly:
      return items.filter(isCallQueueItemPhoneCall);
    default:
      return items;
  }
};

export const getCallQueueWaitingText = (
  totalItems: CallQueueItem[],
  filterValue: CallQueueFilter
): string => {
  const totalCount = totalItems.length;
  if (totalCount > 100) {
    return "100+ Calls Waiting";
  }

  const filteredCount = getItemsFilteredByTypeFilter(
    totalItems,
    filterValue
  ).length;
  if (filteredCount === 0) {
    const callType =
      filterValue === CallQueueFilter.CounterCallsOnly ? "Counter" : "Phone";
    return `0 ${callType} Calls Waiting`;
  }

  return `${filteredCount} Call${filteredCount === 1 ? "" : "s"} Waiting`;
};

const sortItemsByPriority = (items: CallQueueItem[]) => {
  // Priority value is calculated as:
  // skillMultipliedWeight * elapsed time since item creation
  const itemUrlToPriorityRecord = items.reduce<Record<string, number>>(
    (acc, curr) => {
      const skillMultipliedWeight = curr.skillMultipliedWeight;
      const elapsedTimeMinutes = getElapsedTimeSeconds(curr.timeCreated);
      return { ...acc, [curr.url]: skillMultipliedWeight * elapsedTimeMinutes };
    },
    {}
  );

  // Sort items by descending priority value
  const sortedItems = [...items].sort((a, b) => {
    const aPriority = itemUrlToPriorityRecord[a.url];
    const bPriority = itemUrlToPriorityRecord[b.url];
    return bPriority - aPriority;
  });

  return sortedItems;
};

export const getNextCallQueueItem = (
  items: CallQueueItem[]
): CallQueueItem | undefined => {
  return sortItemsByPriority(items)[0];
};

export const getCallQueueItemSkillName = (item: CallQueueItem) => {
  const topSkillRequirement = item.skillRequirements.reduce<
    CallQueueItem["skillRequirements"][0] | undefined
  >((acc, curr) => {
    if (!acc) return curr;
    return curr.weight > acc.weight ? curr : acc;
  }, undefined);
  return topSkillRequirement?.skill.name ?? "";
};

export const createTaskAssignmentForCallQueueItem = (
  item: CallQueueItem,
  agent: User
) => {
  const idString = item.url.split("/").pop() ?? "";
  const id = parseInt(idString);

  if (isNaN(id)) {
    throw new Error("Invalid RTT id");
  }

  return createTaskAssignment(id, {
    status: "ACCEPTED",
    assignee_url: agent.url,
  });
};
